export const translate = (language: string, index: number) => {
  switch (language) {
    case "FR":
      return dictionary[index].FR;
    case "AR":
      return dictionary[index].AR;
    case "EN":
      return dictionary[index].EN;
    case "DE":
      return dictionary[index].DE;
    case "IT":
      return dictionary[index].IT;
    case "ES":
      return dictionary[index].SP;
    case "PT":
      return dictionary[index].PT;
    case "TR":
      return dictionary[index].TR;
    case "ZH":
      return dictionary[index].ZH;
    case "JA":
      return dictionary[index].RU;
    case "RU":
      return dictionary[index].RU;
    default:
      return dictionary[index].FR;
  }
};

// to add new term to translate
// add a new object with the following form:
// {FR:"",EN:"",AR:"",DE:"",IT:"",SP:"",PT:"",TR:"",ZH:"",JA:""}
//  12

const dictionary = [
  {
    FR: "Entrer",
    EN: "Enter",
    AR: "دخول",
    DE: "Eingeben",
    IT: "Entrare",
    SP: "Entrar",
    PT: "Entrar",
    TR: "Girmek",
    ZH: "进入",
    JA: "入る",
    RU: "Введите",
  }, // 0
  {
    FR: "Choisir votre Langue",
    EN: "Choose your language",
    AR: "إختر اللغة",
    DE: "Wähle deine Sprache",
    IT: "Scegli la tua lingua",
    SP: "Elige tu idioma",
    PT: "Escolha seu idioma",
    TR: "Dilinizi seçin",
    ZH: "选择你的语言",
    JA: "言語を選んでください",
    RU: "Выберите ваш язык",
  }, // 1
  {
    FR: "Savoir plus",
    EN: "More",
    AR: "المزيد",
    DE: "Mehr",
    IT: "Di Più",
    SP: "Más",
    PT: "Mais",
    TR: "Daha",
    ZH: "更多",
    JA: "もっと",
    RU: "Больше",
  }, // 2
  {
    FR: "Annuler",
    EN: "Cancel",
    AR: "إلغاء",
    DE: "Abbrechen",
    IT: "Annulla",
    SP: "Cancelar",
    PT: "Cancelar",
    TR: "İptal etmek",
    ZH: "取消",
    JA: "キャンセル",
    RU: "Отменить",
  }, // 3
  {
    FR: "Actualiser",
    EN: "Refresh",
    AR: "تحديث",
    DE: "Aktualisieren",
    IT: "Ricaricare",
    SP: "Actualizar",
    PT: "Atualizar",
    TR: "Yenile",
    ZH: "刷新",
    JA: "リフレッシュ",
    RU: "Обновить",
  }, // 4
  {
    FR: "Oups! Une erreur est survenu, veuillez actualiser la page",
    EN: "Oups! There must have been an error, please refresh the page",
    AR: "عفوًا! حدث خطأ ، يرجى تحديث الصفحة",
    DE: "Ups! Es ist ein Fehler aufgetreten. Bitte aktualisieren Sie die Seite",
    IT: "Oups! Deve essersi verificato un errore, aggiorna la pagina",
    SP: "Oups! Debe haber habido un error, por favor actualice la página",
    PT: "Oups! Deve ter havido um erro, atualize a página",
    TR: "Oups! Bir hata olmuş olmalı, lütfen sayfayı yenileyin",
    ZH: "哎呀！肯定有错误，请刷新页面",
    JA:
      "おーっ！エラーが発生している可能性があります。ページを更新してください",
    RU: "Упс! Произошла ошибка, пожалуйста, обновите страницу",
  }, // 5
  {
    FR: "Ajouter à mon panier",
    EN: "Add to my cart",
    AR: "أضف إلى سلة التسوق",
    DE: "In meinen Warenkorb legen",
    IT: "Aggiungi al mio carrello",
    SP: "Añadir a mi carrito",
    PT: "Adicionar ao carrinho",
    TR: "Sepetime ekle",
    ZH: "添加到我的购物车",
    JA: "カートに追加",
    RU: "Добавить в корзину",
  }, // 6
  {
    FR: "Retirer du panier",
    EN: "Remove from my cart",
    AR: "أزله من سلة التسوق",
    DE: "Aus dem Korb nehmen",
    IT: "Rimuovere dal carrello",
    SP: "Eliminar de mi carrito",
    PT: "Remover do meu carrinho",
    TR: "Sepetimden kaldır",
    ZH: "从我的购物车中删除",
    JA: "カートから削除",
    RU: "Удалить из корзины",
  }, // 7
  {
    FR: "Lien copié",
    EN: "Link copied",
    AR: "تم نسخ الرابط",
    DE: "Link kopiert",
    IT: "Link copiato",
    SP: "Enlace copiado",
    PT: "Link copiado",
    TR: "Bağlantı kopyalandı",
    ZH: "链接已复制",
    JA: "リンクをコピーしました",
    RU: "Ссылка скопирована",
  }, // 8
  {
    FR: "Produit ajouté",
    EN: "Product added",
    AR: "تمت إضافة المنتج",
    DE: "Produkt hinzugefügt",
    IT: "Prodotto aggiunto",
    SP: "Producto agregado",
    PT: "Produto adicionado",
    TR: "Ürün eklendi",
    ZH: "已添加产品",
    JA: "追加された製品",
    RU: "Продукт добавлен",
  }, // 9
  {
    FR: "Produit retiré",
    EN: "Product removed",
    AR: "تمت إزالة المنتج",
    DE: "Produkt entfernt",
    IT: "Prodotto rimosso",
    SP: "Producto eliminado",
    PT: "Produto removido",
    TR: "Ürün kaldırıldı",
    ZH: "产品已移除",
    JA: "削除された製品",
    RU: "Продукт удален",
  }, // 10
  {
    FR: "Votre panier est vide",
    EN: "Your cart is empty",
    AR: "سلة التسوق فارغة",
    DE: "Ihr Warenkorb ist leer",
    IT: "Il tuo carrello è vuoto",
    SP: "Tu carrito está vacío",
    PT: "Seu carrinho está vazio",
    TR: "Sepetiniz boş",
    ZH: "您的购物车是空的",
    JA: "あなたのカートは空です",
    RU: "Ваша корзина пуста",
  }, // 11
  {
    FR: "Ajoutez des produits et passez votre commande en ligne.",
    EN: "Add products and place your order online",
    AR: "أضف منتجات واحصل على طلبك عبر الانترنت",
    DE: "Fügen Sie Produkte hinzu und bestellen Sie online",
    IT: "Aggiungi prodotti e invia il tuo ordine online",
    SP: "Agregue productos y haga su pedido en línea",
    PT: "Adicione produtos e faça seu pedido on-line",
    TR: "Ürün ekleyin ve siparişinizi çevrimiçi verin",
    ZH: "添加产品并在线下订单",
    JA: "製品を追加してオンラインで注文する",
    RU: "Добавьте продукты и оформите заказ онлайн.",
  }, // 12
  {
    FR: "Mon panier",
    EN: "My cart",
    AR: "سلة التسوق",
    DE: "Mein Warenkorb",
    IT: "Il mio carrello",
    SP: "Mi carrito",
    PT: "Meu carrinho",
    TR: "Sepetim",
    ZH: "我的车",
    JA: "私のカート",
    RU: "Моя корзина",
  }, // 13
  {
    FR: "Vos informations",
    EN: "Your informations",
    AR: "معلوماتكم",
    DE: "Ihre Informationen",
    IT: "Le tue informazioni",
    SP: "Su información",
    PT: "Sua informação",
    TR: "Bilgileriniz",
    ZH: "您的资料",
    JA: "あなたの情報",
    RU: "Ваша информация",
  }, // 14
  {
    FR: "Récapitulatif",
    EN: "Summary",
    AR: "ملخص",
    DE: "Zusammenfassung",
    IT: "Sommario",
    SP: "Resumen",
    PT: "Sumário",
    TR: "Özet",
    ZH: "摘要",
    JA: "概要",
    RU: "Резюме",
  }, // 15
  {
    FR: "Suivant",
    EN: "Next",
    AR: "التالي",
    DE: "Nächster",
    IT: "Il prossimo",
    SP: "próximo",
    PT: "Próximo",
    TR: "Sonraki",
    ZH: "下一个",
    JA: "次",
    RU: "Следующий",
  }, // 16
  {
    FR: "Retour",
    EN: "Back",
    AR: "إلى الوراء",
    DE: "Zurück",
    IT: "Indietro",
    SP: "atrás",
    PT: "Costas",
    TR: "Geri",
    ZH: "背部",
    JA: "バック",
    RU: "Назад",
  }, // 17
  {
    FR: "Total",
    EN: "Total",
    AR: "المجموع",
    DE: "Total",
    IT: "Total",
    SP: "Total",
    PT: "Total",
    TR: "Toplam",
    ZH: "总",
    JA: "合計",
    RU: "Итого",
  }, // 18
  {
    FR: "Frais de livraison",
    EN: "Delivery cost",
    AR: "تكاليف التوصيل",
    DE: "Versandkosten",
    IT: "Costo di consegna",
    SP: "Coste de envío",
    PT: "Custo de entrega",
    TR: "Teslimat maliyeti",
    ZH: "运输成本",
    JA: "配送費用",
    RU: "Стоимость доставки",
  }, // 19
  {
    FR: "Gratuit",
    EN: "Free",
    AR: "مجاني",
    DE: "Kostenlos",
    IT: "Gratuito",
    SP: "Gratis",
    PT: "Livre",
    TR: "Bedava",
    ZH: "自由",
    JA: "自由",
    RU: "Бесплатно",
  }, // 20
  {
    FR: "Renseignez vos informations",
    EN: "Fill in your information",
    AR: "املأ معلومات الخاصة بك",
    DE: "Geben Sie Ihre Lieferinformationen ein",
    IT: "Inserisci le informazioni di consegna",
    SP: "Complete su información de entrega",
    PT: "Preencha suas informações de entrega",
    TR: "Teslimat bilgilerinizi doldurun",
    ZH: "填写您的送货信息",
    JA: "配送情報を入力してください",
    RU: "Заполните вашу информацию",
  }, // 21
  {
    FR: "Prénom",
    EN: "Name",
    AR: "الاسم",
    DE: "Name",
    IT: "Nome",
    SP: "Nombre",
    PT: "Nome",
    TR: "İsim",
    ZH: "名称",
    JA: "名前",
    RU: "Имя",
  }, // 22
  {
    FR: "Nom",
    EN: "Last name",
    AR: "اللقب",
    DE: "Nachname",
    IT: "Cognome",
    SP: "Apellido",
    PT: "Sobrenome",
    TR: "Soyadı",
    ZH: "姓氏",
    JA: "姓",
    RU: "Фамилия",
  }, // 23
  {
    FR: "Numéro de téléphone",
    EN: "Phone number",
    AR: "رقم الهاتف",
    DE: "Telefonnummer",
    IT: "Numero di telefono",
    SP: "Número de teléfono",
    PT: "Número de telefone",
    TR: "Telefon numarası",
    ZH: "电话号码",
    JA: "電話番号",
    RU: "Номер телефона",
  }, // 24
  {
    FR: "Email",
    EN: "Email",
    AR: "البريد الإلكتروني",
    DE: "Email",
    IT: "Email",
    SP: "Email",
    PT: "O email",
    TR: "E-posta",
    ZH: "电子邮件",
    JA: "Eメール",
    RU: "Электронная почта",
  }, // 25
  {
    FR: "Note",
    EN: "Note",
    AR: "ملاحظة",
    DE: "Hinweis",
    IT: "Nota",
    SP: "Nota",
    PT: "Nota",
    TR: "Not",
    ZH: "注意",
    JA: "注意",
    RU: "Примечание",
  }, // 26
  {
    FR: "Ce champ est requis",
    EN: "This field is required",
    AR: "هذه الخانة مطلوبه",
    DE: "Dieses Feld wird benötigt",
    IT: "Questo campo è obbligatorio",
    SP: "este campo es requerido",
    PT: "Este campo é obrigatório",
    TR: "Bu alan gereklidir",
    ZH: "这是必填栏",
    JA: "この項目は必須です",
    RU: "Это поле обязательно для заполнения",
  }, // 27
  {
    FR: "Informations",
    EN: "information",
    AR: "معلومات ",
    DE: "Lieferinformationen",
    IT: "Informazioni di consegna",
    SP: "Información de entrega",
    PT: "Informação de entrega",
    TR: "Teslimat Bilgisi",
    ZH: "配送信息",
    JA: "配送に関する情報",
    RU: "Информация",
  }, // 28
  {
    FR: "Ma commande",
    EN: "Delivery information",
    AR: "طلباتي",
    DE: "Meine Bestellung",
    IT: "Il mio ordine",
    SP: "Mi pedido",
    PT: "Meu pedido",
    TR: "Benim siparişim",
    ZH: "我的订单",
    JA: "私の注文",
    RU: "Мой заказ",
  }, // 29
  {
    FR: "Nom et prènom",
    EN: "First name and last name",
    AR: "الاسم و اللقب",
    DE: "Vorname und Nachname",
    IT: "Nome e cognome",
    SP: "Nombre y apellido",
    PT: "Nome e sobrenome",
    TR: "İsim soyisim",
    ZH: "名和姓",
    JA: "姓と名",
    RU: "Имя и фамилия",
  }, // 30
  {
    FR: "Vider le panier",
    EN: "Empty the cart",
    AR: "أفرغ السلة",
    DE: "Leere",
    IT: "Carrello vuoto",
    SP: "Carro vacío",
    PT: "Carrinho vazio",
    TR: "Boş araba",
    ZH: "空车",
    JA: "空のカート",
    RU: "Опустошить корзину",
  }, // 31
  {
    FR: "Confirmer",
    EN: "Confirm",
    AR: "وافق",
    DE: "Bestätigen",
    IT: "Confermare",
    SP: "Confirmar",
    PT: "confirme",
    TR: "Onaylamak",
    ZH: "确认",
    JA: "確認",
    RU: "Подтвердить",
  }, // 32
  {
    FR: "Télécharger le ticket",
    EN: "Download ticket",
    AR: "نزل التذكرة",
    DE: "Ticket herunterladen",
    IT: "Scarica il biglietto",
    SP: "Descargar boleto",
    PT: "Download do ticket",
    TR: "Bilet indir",
    ZH: "下载门票",
    JA: "チケットをダウンロード",
    RU: "Скачать билет",
  }, // 33
  {
    FR: "Votre commande est en cours de traitement",
    EN: "Your order is being processed",
    AR: "سيتم تجهيز طلبك",
    DE: "Ihre Bestellung wird bearbeitet",
    IT: "Il vostro ordine è in fase di elaborazione",
    SP: "Tu pedido está siendo procesado",
    PT: "Seu pedido está sendo processado",
    TR: "Siparişiniz işleniyor",
    ZH: "您的订单正在处理中",
    JA: "ご注文は処理されています",
    RU: "Ваш заказ обрабатывается",
  }, // 34
  {
    FR: "Utiliser le code ci-dessous en cas de besoin",
    EN: "Use this code below when needed",
    AR: "استخدم هذا الرمز أدناه عند الحاجة",
    DE: "Verwenden Sie diesen Code bei Bedarf unten",
    IT: "Utilizzare questo codice di seguito quando necessario",
    SP: "Use este código a continuación cuando sea necesario",
    PT: "Use este código abaixo quando necessário",
    TR: "Gerektiğinde aşağıdaki bu kodu kullanın",
    ZH: "需要时在下方使用此代码",
    JA: "必要に応じて、以下のこのコードを使用してください",
    RU: "Используйте этот код ниже при необходимости",
  }, // 35
  {
    FR: "Quantité ",
    EN: "Quantity ",
    AR: "الكمية",
    DE: "Menge ",
    IT: "Quantità ",
    SP: "Cantidad ",
    PT: "Quantidade ",
    TR: "Miktar ",
    ZH: "数量在 ",
    JA: "数量 ",
    RU: "Количество ",
  }, // 36
  {
    FR: "Prix unitaire:",
    EN: "Unit price:",
    AR: ":سعر الوحدة",
    DE: "Stückpreis:",
    IT: "Prezzo unitario:",
    SP: "Precio unitario:",
    PT: "Preço unitário:",
    TR: "Birim fiyatı:",
    ZH: "单价：",
    JA: "単価：",
    RU: "Цена за единицу:",
  }, // 37
  {
    FR: "Quantité modifié",
    EN: "Quantity updated",
    AR: "تم تحديث الكمية",
    DE: "Menge aktualisiert",
    IT: "Quantità aggiornata",
    SP: "Cantidad actualizada",
    PT: "Quantidade atualizada",
    TR: "Miktar güncellendi",
    ZH: "数量已更新",
    JA: "更新された数量",
    RU: "Количество обновлено",
  }, // 38
  {
    FR: "Chercher",
    EN: "Search",
    AR: "ابحث",
    DE: "Suche",
    IT: "Ricerca",
    SP: "Buscar",
    PT: "Procurar",
    TR: "Arama",
    ZH: "搜索",
    JA: "探す",
    RU: "Поиск",
  }, // 39
  {
    FR: "Choisir vos accompagnements",
    EN: "Choose a supplement",
    AR: "اختر المكمل",
    DE: "Wählen Sie ein Supplement",
    IT: "Scegli un supplemento",
    SP: "Elige un suplemento",
    PT: "Escolha um suplemento",
    TR: "Bir ekleme seçin",
    ZH: "选择补充品",
    JA: "サプリメントを選択",
    RU: "Выберите добавку",
  }, // 40
  {
    FR: "Maximum ",
    EN: "Maximum ",
    AR: " تتطلب هذه الزيادة بحد أقصاه",
    DE: "Maximum ",
    IT: "Massimo ",
    SP: "Máximo ",
    PT: "Máximo ",
    TR: "Maksimum ",
    ZH: "最大 ",
    JA: "最大 ",
    RU: "Максимум ",
  }, // 41
  {
    FR: "choix ",
    EN: "choices ",
    AR: "خيارات",
    DE: "Wahl ",
    IT: "scelte ",
    SP: "opciones ",
    PT: "escolhas ",
    TR: "seçenekler ",
    ZH: "选择 ",
    JA: "選択肢 ",
    RU: "выбор ",
  }, // 42
  {
    FR: "Minimum ",
    EN: "Minimum ",
    AR: "يتطلب هذا الإضافي حدًا أدنى من",
    DE: "Minimum ",
    IT: "Minimo ",
    SP: "Mínimo ",
    PT: "Mínimo ",
    TR: "Minimum ",
    ZH: "最小 ",
    JA: "最小 ",
    RU: "Минимум ",
  }, // 43
  {
    FR: "Adresse",
    EN: "Address",
    AR: "عنوان",
    DE: "Adresse",
    IT: "Indirizzo",
    SP: "Dirección",
    PT: "Endereço",
    TR: "Adres",
    ZH: "地址",
    JA: "住所",
    RU: "Адрес",
  }, // 44
  {
    FR: "Fermer",
    EN: "Close",
    AR: "أغلق",
    DE: "Schließen",
    IT: "Chiudere",
    SP: "Cerrar",
    PT: "Fechar",
    TR: "Kapat",
    ZH: "关闭",
    JA: "閉じる",
    RU: "Закрыть",
  }, // 45
  {
    FR: "Sélectionner vôtre moyen de paiement",
    EN: "Payment Method",
    AR: "طريقة الدفع",
    DE: "Zahlungsmethode",
    IT: "Metodo di pagamento",
    SP: "Método de pago",
    PT: "Método de pagamento",
    TR: "Ödeme Yöntemi",
    ZH: "支付方式",
    JA: "支払い方法",
    RU: "Способ оплаты",
  }, // 46
  {
    FR: "Paiement sur place",
    EN: "Click and collect",
    AR: "الدفع عند الاستلام",
    DE: "Click and collect",
    IT: "Click and collect",
    SP: "Click and collect",
    PT: "Click and collect",
    TR: "Click and collect",
    ZH: "Click and collect",
    JA: "Click and collect",
    RU: "Самовывоз",
  }, // 47
  {
    FR: "Paiement en ligne CB",
    EN: "Payment by credit card",
    AR: "الدفع ببطاقة الائتمان",
    DE: "Zahlung per Kreditkarte",
    IT: "Pagamento con carta di credito",
    SP: "Pago con tarjeta de crédito",
    PT: "Pagamento por cartão de crédito",
    TR: "Kredi kartı ile ödeme",
    ZH: "信用卡支付",
    JA: "クレジットカード払い",
    RU: "Оплата кредитной картой",
  }, // 48
  {
    FR: "Vous devez accepter les conditions générales de vente de MenuMe.",
    EN: "You must accept Menume's general Term and conditions.",
    AR: "يجب قبول الشروط العامة للبيع",
    DE:
      "Sie müssen die allgemeinen Geschäftsbedingungen von Menume akzeptieren.",
    IT: "Devi accettare i termini e le condizioni generali di MenuMe.",
    SP: "Debes aceptar los términos y condiciones generales de Menume.",
    PT: "Você deve aceitar os termos e condições gerais da MenuMe.",
    TR: "Menume'nin genel şartlarını kabul etmelisiniz.",
    ZH: "您必须接受MenuMe的一般条款和条件。",
    JA: "MenuMeの一般取引条件に同意する必要があります。",
    RU: "Вы должны принять общие условия продажи MenuMe.",
  }, // 49
  {
    FR: "Non disponible",
    EN: "Not available",
    AR: "غير متاح",
    DE: "Nicht verfügbar",
    IT: "Non disponibile",
    SP: "No disponible",
    PT: "Não disponível",
    TR: "Mevcut değil",
    ZH: "不可用",
    JA: "利用できません",
    RU: "Недоступно",
  }, // 50
  {
    FR: "Paiement en ligne avec SKIPCASH",
    EN: "Payment using skipcash",
    AR: "الدفع ببطاقة الائتمان",
    DE: "Zahlung mit Skipcash",
    IT: "Pagamento con skipcash",
    SP: "Pago con skipcash",
    PT: "Pagamento usando skipcash",
    TR: "Skipcash ile ödeme",
    ZH: "使用skipcash支付",
    JA: "skipcashでの支払い",
    RU: "Оплата с помощью skipcash",
  }, // 51
  {
    FR: "Paiement effectué avec succée",
    EN: "Payment succeeded",
    AR: "تم الدفع بنجاح",
    DE: "Zahlung erfolgreich",
    IT: "Pagamento riuscito",
    SP: "Pago exitoso",
    PT: "Pagamento efetuado com sucesso",
    TR: "Ödeme başarılı",
    ZH: "支付成功",
    JA: "支払い成功",
    RU: "Оплата прошла успешно",
  }, // 52
  {
    FR: "Paiement echoué",
    EN: "Payment failed",
    AR: "فشل الدفع",
    DE: "Zahlung fehlgeschlagen",
    IT: "Pagamento fallito",
    SP: "Pago fallido",
    PT: "Pagamento falhou",
    TR: "Ödeme başarısız",
    ZH: "支付失败",
    JA: "支払いに失敗しました",
    RU: "Ошибка оплаты",
  }, // 53
  {
    FR: "Rester avec nous !",
    EN: "Stay with us !",
    AR: "ابقى معنا!",
    DE: "Bleib bei uns!",
    IT: "Resta con noi!",
    SP: "¡Quédate con nosotros!",
    PT: "Fique conosco!",
    TR: "Bizimle kal!",
    ZH: "和我们呆在一起！",
    JA: "私たちと一緒にいてください！",
    RU: "Останьтесь с нами!",
  }, // 54
  {
    FR: "Temps de preparation:",
    EN: "Prep time:",
    AR: ":وقت التحضير",
    DE: "Vorbereitungszeit:",
    IT: "Tempo di preparazione:",
    SP: "Tiempo de preparación:",
    PT: "Tempo de preparação:",
    TR: "Hazırlık süresi:",
    ZH: "准备时间：",
    JA: "準備時間：",
    RU: "Время подготовки:",
  }, // 55
  {
    FR: "non précisé",
    EN: "not specified",
    AR: ":غير محدد",
    DE: "nicht angegeben",
    IT: "non specificato",
    SP: "no especificado",
    PT: "não especificado",
    TR: "belirtilmemiş",
    ZH: "未指定",
    JA: "指定されていない",
    RU: "не указано",
  }, // 56
  {
    FR: "Prix total des suppléments",
    EN: "Total supplements price",
    AR: ":إجمالي سعر المكملات الغذائية",
    DE: "Gesamtpreis der Ergänzungen",
    IT: "Prezzo totale supplementi",
    SP: "Precio total suplementos",
    PT: "Preço total dos suplementos",
    TR: "Toplam takviye fiyatı",
    ZH: "补品总价",
    JA: "サプリメントの合計金額",
    RU: "Общая цена добавок",
  }, // 57
  {
    FR: "Suppléments gratuits",
    EN: "Free supplements",
    AR: ":مكملات مجانية",
    DE: "Kostenlose Ergänzungen",
    IT: "Supplementi gratuiti",
    SP: "Suplementos gratis",
    PT: "Suplementos grátis",
    TR: "Ücretsiz takviyeler",
    ZH: "免费补充剂",
    JA: "無料のサプリメント",
    RU: "Бесплатные добавки",
  }, // 58
  {
    FR: "Supplément gratuit",
    EN: "Free supplement",
    AR: ":ملحق مجاني",
    DE: "Kostenlose Ergänzung",
    IT: "Supplemento gratuito",
    SP: "Suplemento gratuito",
    PT: "Suplemento gratuito",
    TR: "Ücretsiz ek",
    ZH: "免费补充",
    JA: "無料サプリメント",
    RU: "Бесплатная добавка",
  }, // 59
];

export default dictionary;
