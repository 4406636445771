/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-cycle */
//  local imports
import React, { FC, useState } from "react";
import styled from "styled-components";
import { Globe } from "react-feather";
import { useQuery } from "@apollo/client";

//  libraries imports
import { ModalSettings } from ".";
import { GetLanguage } from "../utils/languages";
import {
  GetAccessMenuDetailQuery,
  GetAccessMenuDetailQueryVariables,
} from "../api/types";
import { GETMENU } from "../api/query.api";
// import { GetLanguage } from "../utils/languages";
import { EN, FR, AR, ZH, DE, IT, JA, PT, ES, TR, RU } from "../Images";
import client, {
  localDomain,
  localIdentifier,
  GetLocalStateQuery,
  localLanguage,
  GET_LOCAL_STATE,
} from "../Graphql/apolloClient";
import { URL } from "../api/config";

const LanguageSelector: FC = () => {
  //  STATES
  const [showLanguage, setShowLanguage] = React.useState(false);

  const [localState, setlocalState] = useState<GetLocalStateQuery | undefined>(
    undefined
  );
  client
    .watchQuery<GetLocalStateQuery>({
      query: GET_LOCAL_STATE,
      fetchPolicy: "cache-only",
    })
    .subscribe({
      next: ({ data }) => {
        setlocalState(data);
      },
      error: (e) => console.error(e),
    });
  console.log("localState", localState);
  const languageState = localState?.language || "EN";

  //  FUNCTIONS
  const handleClose = () => {
    setShowLanguage(false);
  };

  const hadleClickSettings = () => {
    setShowLanguage(true);
  };

  const { data } = useQuery<
    GetAccessMenuDetailQuery,
    GetAccessMenuDetailQueryVariables
  >(GETMENU, {
    variables: {
      input: { domain: localDomain(), identifier: localIdentifier() },
    },
  });

  const languages = data?.getAccessMenuDetail.accessMenu?.languages;

  const getFlag = (language: string) => {
    switch (language) {
      case "AR":
        return AR;
      case "FR":
        return FR;
      case "EN":
        return EN;
      case "DE":
        return DE;
      case "ES":
        return ES;
      case "IT":
        return IT;
      case "ZH":
        return ZH;
      case "JA":
        return RU;
      case "PT":
        return PT;
      case "TR":
        return TR;
      case "RU":
        return RU;
      default:
        return EN;
    }
  };
  return (
    <Wrapper>
      {data?.getAccessMenuDetail.accessMenu?.languages &&
        data?.getAccessMenuDetail.accessMenu?.languages.length > 1 && (
          <button
            type="button"
            onClick={hadleClickSettings}
            className="language-selector"
          >
            <div style={{ width: "14px", height: "14px" }}>
              <Globe size={14} className="color" />
            </div>
            <span>{localState?.language === "JA" ? "RU" : languageState}</span>
          </button>
        )}
      <ModalSettings show={showLanguage} onClose={handleClose}>
        {languages?.map((language, index) => {
          return (
            <div
              tabIndex={0}
              role="button"
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={
                languageState === language.languageCode!
                  ? "language-list selected"
                  : "language-list"
              }
              onClick={() => {
                setShowLanguage(false);
                if (language.languageCode) localLanguage(language.languageCode);
              }}
            >
              <img alt="language" src={getFlag(language.languageCode || "")} />
              <p>
                {GetLanguage(languageState || "", language.languageCode || "")}
              </p>
            </div>
          );
        })}
      </ModalSettings>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  z-index: 999;
  margin: auto auto auto 0;
  grid-area: language;

  .language-selector {
    width: 50px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    border: none;
    border-radius: ${({ theme }) => (theme.buttonBackground ? 0 : "20px")};
    background-color: ${({ theme }) =>
      theme.buttonBackground ? "none" : "rgba(127, 127, 127, 0.3)"};
    background-image: ${({ theme }) =>
      theme.buttonBackground ? `url(${URL}${theme.buttonBackground})` : "none"};
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
    outline: none;
    span {
      font-size: 12px;
      color: ${(props) => props.theme.paletteColors.primary};
      margin: 3px;
    }
  }
  .color {
    color: ${(props) => props.theme.paletteColors.primary};
  }
`;

export default LanguageSelector;
