/* eslint-disable react/no-array-index-key */
// libreries
import React, { FC, useEffect, useContext, useState } from "react";
import { ArrowLeft } from "react-feather";
import { useHistory, useParams, Route } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import ContentLoader from "react-content-loader";
import { ThemeContext } from "styled-components";
import { transparentize } from "polished";
import { translate } from "../../utils/dictionary";

//  local imports
import {
  Subtitle,
  Item,
  MenuCarousel,
  Cart,
  CategoriesList,
  Appbar,
  Subcategory,
  ModalPromotion,
  AdProduct,
} from "../../Commons";
import { ModalProduct } from "../../components";

import client, {
  localDomain,
  localIdentifier,
  GetLocalStateQuery,
  GET_LOCAL_STATE,
} from "../../Graphql/apolloClient";
import {
  GetAccessMenuCategoryDetailQuery,
  GetAccessMenuCategoryDetailQueryVariables,
  GetAccessMenuDetailQueryVariables,
  GetAccessMenuDetailQuery,
  GetMenuThemeQueryVariables,
  GetMenuThemeQuery,
  AccessMenuAdPayload,
  CreateProductNavigationMutation,
  CreateProductNavigationMutationVariables,
  CreateAdLogMutation,
  CreateAdLogMutationVariables,
  AccessMenuProductPayload,
  GetCategoryWidgetsQuery,
  GetCategoryWidgetsQueryVariables,
  MenuAdProductPayload,
} from "../../api/types";
import {
  GETMENU,
  GETMENUTHEME,
  GET_CATEGORY,
  GET_WIDGETS,
} from "../../api/query.api";
import { CREATEPRODUCTNAVIGATION, CREAT_AD_LOG } from "../../api/mutation.api";
import { MENU_ADS_URL } from "../../api/config";

import { lock, unlock, getLighterOrDarker } from "../../utils/util";
import {
  // scrollWindowToBottom,
  getWidgetStyles,
  getProductPicture,
} from "./Category-utils";
import { MasterContainer, Wrapper, Separator } from "./style";

//  types
type Display = "GRID" | "LIST" | "CENTER";
type CategoryParams = {
  id: string | undefined;
  category: string | undefined;
};

const Category: FC = () => {
  const history = useHistory();
  const { id, category: categoryIdentifier } = useParams<CategoryParams>();
  const themeContext = useContext(ThemeContext);

  // STATES
  const [showPromotion, setShowPromotion] = React.useState(false);
  const [ad, setAd] = React.useState<AccessMenuAdPayload>({});
  const [coordsY, setCoordsY] = React.useState<number[]>([]);
  const [coordsX, setCoordsX] = React.useState<number[]>([]);
  const [target, setTarget] = React.useState<number>();
  const [navScrollStyles, setNavScrollStyles] = React.useState<{
    start: number;
    width: number;
  } | null>(null);

  const [localState, setlocalState] = useState<GetLocalStateQuery | undefined>(
    undefined
  );
  client
    .watchQuery<GetLocalStateQuery>({
      query: GET_LOCAL_STATE,
      fetchPolicy: "cache-only",
    })
    .subscribe({
      next: ({ data }) => {
        setlocalState(data);
      },
      error: (e) => console.error(e),
    });
  const localLanguage = localState?.language || "EN";
  const localShop = localState?.shop;

  const { data } = useQuery<
    GetAccessMenuDetailQuery,
    GetAccessMenuDetailQueryVariables
  >(GETMENU, {
    variables: {
      input: { domain: localDomain(), identifier: localIdentifier() },
    },
  });
  const menu = data?.getAccessMenuDetail.accessMenu;

  const { data: categoryDetails, loading: categoryLoading } = useQuery<
    GetAccessMenuCategoryDetailQuery,
    GetAccessMenuCategoryDetailQueryVariables
  >(GET_CATEGORY, {
    skip: !categoryIdentifier || !localIdentifier() || !localDomain(),
    variables: {
      input: {
        domain: localDomain(),
        menu: localIdentifier(),
        identifier: categoryIdentifier,
      },
    },
  });
  const category = categoryDetails?.getAccessMenuCategoryDetail?.category;

  const { data: widgets, loading: loadingWidgets } = useQuery<
    GetCategoryWidgetsQuery,
    GetCategoryWidgetsQueryVariables
  >(GET_WIDGETS, {
    variables: {
      input: {
        area: categoryDetails?.getAccessMenuCategoryDetail?.category?.id || "",
      },
    },
    fetchPolicy: "network-only",
  });

  const { data: theme } = useQuery<
    GetMenuThemeQuery,
    GetMenuThemeQueryVariables
  >(GETMENUTHEME, {
    variables: {
      input: { menu: data?.getAccessMenuDetail.accessMenu?.id || "" },
    },
  });
  const menuTheme = theme?.getMenTheme.menuTheme;

  //  MUTATIONS
  const [productStat] = useMutation<
    CreateProductNavigationMutation,
    CreateProductNavigationMutationVariables
  >(CREATEPRODUCTNAVIGATION);

  const [adStat] = useMutation<
    CreateAdLogMutation,
    CreateAdLogMutationVariables
  >(CREAT_AD_LOG);

  // LOCAL VARIABLES
  const language: number =
    menu?.languages?.findIndex((lang) => lang.languageCode === localLanguage) ||
    0;
  const shop = !window.location.origin.includes("qr");
  const widgetsList = widgets?.getAreaWidgets.widgets.filter(
    (widget) => widget.isVisible
  );
  const subcategories = category?.subCategories;

  // FUNCTIONS

  // useEffect(() => {
  //   const offset = 0;
  //   if (adsList && adsList.length > 0) {
  //     offset = 230;
  //   } else {
  //     offset = 30;
  //   }
  //   setHeaderOpacity((window.pageYOffset - offset) / 10);
  // }, [adsList]);

  const handleClose = () => {
    history.replace({
      pathname: `/${id}/menu/${categoryIdentifier}`,
    });
    unlock();
    // setShow(false);
    setShowPromotion(false);
  };

  const handleShowProduct = async (product: AccessMenuProductPayload) => {
    setShowPromotion(false);
    try {
      await productStat({
        variables: {
          input: {
            kind: "PRODUCT_ENTRY",
            device: localStorage.getItem("deviceId"),
            product: product.id || "",
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
    if (
      product.picture?.fileUrl !== null ||
      product.longDescriptions?.[0].value !== ""
    ) {
      lock();
    }

    // simple push no need replace
    history.push({
      pathname: `/${id}/menu/${categoryIdentifier}/${product?.identifier}`,
    });
  };

  const handleShowAdProduct = async (product: MenuAdProductPayload) => {
    setShowPromotion(false);

    try {
      await productStat({
        variables: {
          input: {
            kind: "PRODUCT_ENTRY",
            device: localStorage.getItem("deviceId"),
            product: product.id || "",
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
    if (
      product.picture?.fileUrl !== null ||
      product.longDescriptions?.[0].value !== ""
    ) {
      lock();
    }
    const productCategoryIdentifier = menu?.categories?.filter(
      (categories) => categories.id === product.parentCategory
    )?.[0]?.identifier;
    // simple push no need replace
    if (productCategoryIdentifier) {
      history.push({
        pathname: `/${id}/menu/${productCategoryIdentifier}/${product?.identifier}`,
      });
    }
  };

  const hadleClickAd = async (menuAd: AccessMenuAdPayload) => {
    try {
      await adStat({
        variables: {
          input: {
            ad: menuAd.id || "",
            kind: "AD_CLICK",
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
    setAd(menuAd);
    lock();
    setShowPromotion(true);
  };

  /* *** SCROLL LOGIC *** */
  useEffect(() => {
    const tempY: number[] = [];
    const tempX: number[] = [];
    const container = document.getElementById("container__list");
    if (container && subcategories) {
      subcategories.forEach((subcategory, index) => {
        const subCategorySection = document.getElementById(
          `section-${subcategory?.identifier}`
        );
        const subCategoryLi = document.getElementById(
          `li-${subcategory?.identifier}`
        );
        //  add coords for X and Y
        if (subCategorySection && subCategoryLi) {
          tempX.push(subCategoryLi.offsetLeft);
          tempY.push(subCategorySection.offsetTop);
        }

        // add End limits
        if (index === subcategories!.length - 1) {
          if (subCategoryLi) {
            tempX.push(subCategoryLi.offsetLeft + subCategoryLi.offsetWidth);
            tempY.push(container.scrollHeight);
          }
        }
      });
      setCoordsX(tempX);
      setCoordsY(tempY);
    }
  }, [subcategories]);

  // filter Viewed tabs
  // eslint-disable-next-line consistent-return
  const getViewedSections = () => {
    const container = document.getElementById("container__list");
    if (container) {
      const position = container.scrollTop;
      const scrollEnd = position + container.offsetHeight;
      const selectedTabs = [];
      for (let i = 0; i < coordsY.length; i++) {
        const y = coordsY[i];
        if (i !== coordsY.length - 1) {
          //  Check if the subcategory start or end are in the scroll intervall
          if (
            (y >= position && y < scrollEnd) ||
            (coordsY[i + 1] >= position && coordsY[i + 1] < scrollEnd)
          ) {
            selectedTabs.push(i);
          }
        }
      }
      // in case the height of a subcategory's section is superior than the scroll height
      if (selectedTabs.length === 0) {
        for (let i = 0; i < coordsY.length; i++) {
          if (coordsY[i] <= position && coordsY[i + 1] >= scrollEnd) {
            selectedTabs.push(i);
            break;
          }
        }
      }
      return selectedTabs;
    }
  };

  // eslint-disable-next-line consistent-return
  const handleOnScroll = () => {
    const container = document.getElementById("container__list");

    // check if the product container list are fully shown
    // scrollWindowToBottom();

    if (container) {
      const position = container.scrollTop;
      const scrollEnd = position + container.offsetHeight;
      const positionEnd = container.scrollHeight - container.offsetHeight;
      if (position !== positionEnd && target && coordsY[target] !== position) {
        return;
        // eslint-disable-next-line no-else-return
      } else {
        setTarget(undefined);
      }

      // Select tabs that are shown on the scroll
      const selectedTabs = getViewedSections();

      // get percentages for both horizontal and vertical scrolls
      if (selectedTabs && selectedTabs.length > 0) {
        const startIndex = selectedTabs[0];
        const endIndex = selectedTabs[selectedTabs.length - 1];
        let startPercentigeY = 0;
        const endPercentigeY = Math.abs(
          (scrollEnd - coordsY[endIndex]) /
            (coordsY[endIndex] - coordsY[endIndex + 1])
        );

        if (startIndex !== endIndex) {
          startPercentigeY = Math.abs(
            (position - coordsY[startIndex]) /
              (coordsY[startIndex] - coordsY[startIndex + 1])
          );
        }

        const startSubcategory = subcategories?.[startIndex];
        const endSubcategory = subcategories?.[endIndex];

        const startLi = document.getElementById(
          `li-${startSubcategory?.identifier}`
        );
        const endLi = document.getElementById(
          `li-${endSubcategory?.identifier}`
        );
        if (startLi && endLi) {
          const startPosition =
            startLi.offsetLeft + startPercentigeY * startLi.offsetWidth;
          const barWidth =
            endPercentigeY * endLi.offsetWidth +
            endLi.offsetLeft -
            startPosition;

          setNavScrollStyles({
            start: startPosition,
            width: barWidth,
          });
        }
      } else {
        setNavScrollStyles(null);
      }

      //  filter
      for (let i = 0; i < coordsY.length; i++) {
        if (
          position >= coordsY[i] &&
          position < coordsY[i + 1]
          // selectedTab !== i
        ) {
          const nav = document.getElementById("nav__list");
          if (nav) {
            nav.scrollTo(coordsX[i], 0);
            // setSelectedTab(i);
          }
        }
      }
    }
  };

  const handleOnClickLi = (index: number) => {
    const container = document.getElementById("container__list");

    if (container) {
      // scrollWindowToBottom();
      container.scrollTo(0, coordsY[index]);
      setTarget(index);
    }
  };

  // Those two functions are work around for a bug which is the last render of the component
  // dosen't give you the right positions and sizes of the html elements
  const handleSetRefsX = (el: HTMLDivElement | null, index: number) => {
    if (el && el.offsetTop !== coordsY[index]) {
      setCoordsY(() => {
        const array = [...coordsY];

        array[index] = el.offsetTop;
        if (index === subcategories!.length - 1) {
          array[index + 1] = el.offsetTop + el.clientHeight;
        }
        return array;
      });
    }
  };
  const handleSetRefsY = (el: HTMLDivElement | null, index: number) => {
    if (el && el.offsetLeft !== coordsX[index]) {
      setCoordsX(() => {
        const array = [...coordsX];
        array[index] = el.offsetLeft;
        return array;
      });
    }
  };
  /* *** !END SCROLL LOGIC *** */
  // eslint-disable-next-line consistent-return
  const getAdPoster = () => {
    if (ad.pictures?.poster?.fileName) {
      return (
        <img
          id="poster"
          src={`${MENU_ADS_URL}${ad.pictures?.poster?.fileName}`}
          alt="ad"
        />
      );
    }
    if (ad.pictures?.cover?.fileName) {
      return (
        <img
          id="cover"
          src={`${MENU_ADS_URL}${ad.pictures?.cover?.fileName}`}
          alt="ad"
        />
      );
    }
  };

  return (
    <>
      <MasterContainer>
        <Appbar hideMobile />
        <CategoriesList showOnMobile={false} position="CATEGORY" />
        <Wrapper id="right">
          {localShop === "ON" && shop && (
            <Cart id={menu?.id || ""} className="cart-mobile" />
          )}
          <Route
            path="/:id/menu/:category/:idProduct"
            render={() => (
              <div
                className="modal-container" // no need test
              >
                <ModalProduct
                  show
                  onClose={handleClose}
                  menu={menu?.id || ""}
                />
              </div>
            )}
          />

          <div className="container">
            <header className="category__header">
              <div className="content">
                <button
                  type="button"
                  className="left"
                  onClick={() => {
                    history.replace({ pathname: `/${id}/menu` });
                  }}
                >
                  <ArrowLeft size={18} className="color" />
                </button>
                {category?.names?.[language]?.value ? (
                  <Subtitle
                    decoration={
                      menuTheme?.titleBackground?.[
                        menuTheme.titleBackground.findIndex(
                          (decoration) => decoration.kind === "CATEGORYTITLE"
                        )
                      ]?.value || "#"
                    }
                    title={category?.names?.[language]?.value || ""}
                    className="header__subtitle"
                  />
                ) : (
                  <span style={{ height: "70px", display: "block" }} />
                )}
              </div>
              {subcategories && subcategories?.length > 0 && (
                <div className="nav">
                  <ul className="nav__list" id="nav__list" role="menu">
                    {subcategories?.map((subcategory, index) => (
                      <li key={`li-key-${subcategory.identifier}`}>
                        <div
                          ref={(el) => handleSetRefsY(el, index)}
                          id={`li-${subcategory.identifier}`}
                          className="link"
                          onClick={() => handleOnClickLi(index)}
                          onKeyPress={() => handleOnClickLi(index)}
                          role="button"
                          aria-label="menuItem"
                          tabIndex={0}
                        >
                          {subcategory.names?.[language]?.value}
                        </div>
                      </li>
                    ))}
                    {navScrollStyles && (
                      <div
                        className="nav__scroll"
                        style={{
                          left: navScrollStyles.start,
                          width: navScrollStyles.width,
                        }}
                      />
                    )}
                  </ul>
                </div>
              )}
            </header>
            <div
              className={`container__products-list ${
                subcategories?.length ? "with-nav" : "without-nav"
              } `}
              id="container__list"
              onScroll={handleOnScroll}
            >
              {categoryLoading && (
                <div className="list-product-loader">
                  {["", "", "", "", "", "", "", "", "", "", "", "", ""].map(
                    (_, index) => (
                      <ContentLoader
                        key={index}
                        animate
                        viewBox="0 0 400 35"
                        rtl={themeContext?.direction === "RTL"}
                        backgroundColor={transparentize(
                          0.2,
                          themeContext?.paletteColors?.primary
                        )}
                        foregroundColor={transparentize(
                          0.2,
                          getLighterOrDarker(
                            themeContext?.paletteColors?.primary
                          )
                        )}
                        className="product-loader"
                      >
                        <rect
                          x="17"
                          y="0"
                          rx="2"
                          ry="2"
                          width="88"
                          height="12"
                        />
                        <rect
                          x="17"
                          y="18"
                          rx="2"
                          ry="2"
                          width="52"
                          height="6"
                        />
                        <rect
                          x="330"
                          y="0"
                          rx="2"
                          ry="2"
                          width="50"
                          height="12"
                        />
                      </ContentLoader>
                    )
                  )}
                </div>
              )}
              {!loadingWidgets &&
                widgetsList?.map((widget, index) => {
                  return (
                    <p key={index} style={getWidgetStyles(widget)}>
                      {widget.content?.textContent?.[language]?.value}
                    </p>
                  );
                })}
              <div className="container__i">
                <MenuCarousel
                  className="responsive-carousel"
                  id={data?.getAccessMenuDetail.accessMenu?.id || ""}
                  position="CATEGORY"
                  clickAd={hadleClickAd}
                />
                <Separator alwaysShow />
              </div>

              <div
                className={
                  category?.display === "GRID"
                    ? "container__grid"
                    : "container__list"
                }
              >
                {!categoryLoading &&
                  category?.products?.map((product, index) => {
                    return (
                      product.names?.[language]?.value && (
                        <Item
                          key={index}
                          display={category?.display as Display}
                          show={() => {
                            handleShowProduct(product);
                          }}
                          data={product}
                          image={getProductPicture(product, menu?.logo) || ""}
                        />
                      )
                    );
                  })}
              </div>
              {!categoryLoading &&
                category?.subCategories?.map((subcategory, index) => {
                  return (
                    <Subcategory
                      id={`section-${subcategory?.identifier}`}
                      key={subcategory?.id}
                      ref={(el) => handleSetRefsX(el, index)}
                      categoryId={subcategory?.id || ""}
                      subcategory={subcategory}
                      decoration={
                        menuTheme?.titleBackground?.[
                          menuTheme.titleBackground.findIndex(
                            (decoration) =>
                              decoration.kind === "SUBCATEGORYTITLE"
                          )
                        ]?.value || "#"
                      }
                      language={language}
                      logo={menu?.logo || ""}
                      handleShowProduct={(product) =>
                        handleShowProduct(product)
                      }
                    />
                  );
                })}
            </div>
          </div>
        </Wrapper>
      </MasterContainer>
      <ModalPromotion show={showPromotion} onClose={handleClose}>
        {getAdPoster()}
        {ad.externalUrl !== null && (
          <a
            className="more"
            href={ad.externalUrl}
            target="_blank"
            rel="noopener noreferrer"
            onClick={async () => {
              try {
                await adStat({
                  variables: {
                    input: {
                      ad: ad.id || "",
                      kind: "URL_CLICK",
                    },
                  },
                });
              } catch (e) {
                console.log(e);
              }
            }}
          >
            {translate(localLanguage, 2)}
          </a>
        )}
        <div className="ads-product-list">
          {ad.products &&
            ad.products.length > 0 &&
            ad.products.map((product, index) => {
              return (
                <AdProduct
                  key={product.id || ""}
                  product={product}
                  rank={index}
                  onProductClick={() => handleShowAdProduct(product)}
                />
              );
            })}
        </div>
      </ModalPromotion>
    </>
  );
};

export default Category;
