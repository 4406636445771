const languages = [
  [
    "Arabe",
    "Arabic",
    "العربية",
    "Arabisch",
    "Arabo",
    "Arábica",
    "árabe",
    "Arapça",
    "阿拉伯",
    "アラビア語",
    "Арабский",
  ],
  [
    "Anglais",
    "English",
    "الانجليزية",
    "Englisch",
    "Inglese",
    "Inglés",
    "Inglês",
    "ingilizce",
    "英语",
    "英語",
    "Английский",
  ],
  [
    "Français",
    "French",
    "الفرنسية",
    "Französisch",
    "Francese",
    "Francés",
    "Francês",
    "Fransızca",
    "法文",
    "フランス語",
    "Французский",
  ],
  [
    "Allemand",
    "German",
    "الألمانية",
    "Deutsche",
    "Tedesco",
    "Alemán",
    "Alemão",
    "Almanca",
    "德语",
    "ドイツ人",
    "Немецкий",
  ],
  [
    "Italien",
    "Italian",
    "الايطالية",
    "Italienisch",
    "Italiano",
    "Italiano",
    "Italiano",
    "İtalyan",
    "义大利文",
    "イタリア語",
    "Итальянский",
  ],
  [
    "Espagnole",
    "Spanish",
    "الأسبانية",
    "Spanisch",
    "Spagnolo",
    "Español",
    "Espanhol",
    "İspanyol",
    "西班牙文",
    "スペイン語",
    "Испанский",
  ],
  [
    "Portugaise",
    "Portuguese",
    "البرتغالية",
    "Portugiesisch",
    "Portoghese",
    "Portugués",
    "Português",
    "Portekizce",
    "葡萄牙语",
    "ポルトガル語",
    "Португальский",
  ],
  [
    "Turque",
    "Turkish",
    "التركية",
    "Türkisch",
    "Turco",
    "Turco",
    "Tturco",
    "Turkish",
    "土耳其",
    "トルコ人",
    "Турецкий",
  ],
  [
    "Chinois",
    "Chinese",
    "الصينية",
    "Chinesisch",
    "Cinese",
    "chino",
    "chinês",
    "Çince",
    "中文",
    "中国人",
    "Китайский",
  ],
  [
    "Japonaise",
    "Japanese",
    "اليابانية",
    "japanisch",
    "giapponese",
    "japonés",
    "japonês",
    "Japonca",
    "日本",
    "日本人",
    "Японский",
  ],
  [
    "Russe",
    "Russian",
    "الروسية",
    "Russisch",
    "Russo",
    "Ruso",
    "Russo",
    "Rusça",
    "俄语",
    "ロシア語",
    "Русский",
  ],
];

const getLanguageIndex = (language: string) => {
  switch (language) {
    case "AR":
      return 0;
    case "EN":
      return 1;
    case "FR":
      return 2;
    case "DE":
      return 3;
    case "IT":
      return 4;
    case "ES":
      return 5;
    case "PT":
      return 6;
    case "TR":
      return 7;
    case "ZH":
      return 8;
    case "JA":
      return 10;
    case "RU":
      return 9;
    default:
      return 0;
  }
};

const getTranslateIndex = (translate: string) => {
  switch (translate) {
    case "FR":
      return 0;
    case "EN":
      return 1;
    case "AR":
      return 2;
    case "DE":
      return 3;
    case "IT":
      return 4;
    case "ES":
      return 5;
    case "PT":
      return 6;
    case "TR":
      return 7;
    case "ZH":
      return 8;
    case "JA":
      return 10;
    case "RU":
      return 9;
    default:
      return 0;
  }
};

export const GetLanguage = (translate: string, language: string) => {
  return languages[getLanguageIndex(language)][getTranslateIndex(translate)];
};
