import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import styled from "styled-components";

// eslint-disable-next-line import/no-cycle
import { Subtitle, ModalPromotion, AdProduct, MenuCarousel } from ".";
import { translate } from "../utils/dictionary";
import { lock } from "../utils/util";
import client, {
  localDomain,
  localIdentifier,
  GET_LOCAL_STATE,
  GetLocalStateQuery,
} from "../Graphql/apolloClient";
import { MENU_ADS_URL } from "../api/config";
import {
  GetMenuThemeQuery,
  GetMenuThemeQueryVariables,
  GetAccessMenuDetailQuery,
  GetAccessMenuDetailQueryVariables,
  AccessMenuCategoryPayload,
  CreateCategoryNavigationMutation,
  CreateCategoryNavigationMutationVariables,
  CreateAdLogMutation,
  CreateAdLogMutationVariables,
  FilePayload,
  AccessMenuAdPayload,
  AccessMenuAdPosition,
  NamePayload,
  MenuAdProductPayload,
  CreateProductNavigationMutation,
  CreateProductNavigationMutationVariables,
  GetAccessMenuAdsQuery,
  GetAccessMenuAdsQueryVariables,
} from "../api/types";

import { GETMENUTHEME, GETMENU, GETLISTMENUADS } from "../api/query.api";
import {
  CREATECATEGORYNAVIGATION,
  CREAT_AD_LOG,
  CREATEPRODUCTNAVIGATION,
} from "../api/mutation.api";

type CategoriesListProps = {
  showOnMobile?: boolean;
  position?: AccessMenuAdPosition;
};

type CategoryParams = {
  menuId: string | undefined;
};

const CategoriesList: React.FC<CategoriesListProps> = ({
  position,
  showOnMobile = true,
}) => {
  const { menuId } = useParams<CategoryParams>();
  //   const router = useRouter();
  const history = useHistory();
  const [showPromotion, setShowPromotion] = useState(false);
  const [ad, setAd] = useState<AccessMenuAdPayload>({});

  const [localState, setlocalState] = useState<GetLocalStateQuery | undefined>(
    undefined
  );
  client
    .watchQuery<GetLocalStateQuery>({
      query: GET_LOCAL_STATE,
      fetchPolicy: "cache-only",
    })
    .subscribe({
      next: ({ data }) => {
        setlocalState(data);
      },
      error: (e) => console.error(e),
    });
  const localLanguage = localState?.language || "EN";

  const { data } = useQuery<
    GetAccessMenuDetailQuery,
    GetAccessMenuDetailQueryVariables
  >(GETMENU, {
    skip: !localDomain() || !localIdentifier(),
    variables: {
      input: { domain: localDomain(), identifier: localIdentifier() },
    },
  });

  const menuDetails = data?.getAccessMenuDetail?.accessMenu;
  const { data: theme } = useQuery<
    GetMenuThemeQuery,
    GetMenuThemeQueryVariables
  >(GETMENUTHEME, {
    skip: !menuDetails,
    variables: {
      input: { menu: menuDetails?.id || "" },
    },
  });
  const menuTheme = theme?.getMenTheme.menuTheme;

  const handleNavigate = async (
    link: string | undefined | null,
    id: string | undefined | null
  ) => {
    if (link && id) {
      try {
        await categoryStat({
          variables: {
            input: {
              kind: "CATEGORY_ENTRY",
              device: localStorage.getItem("deviceId"),
              category: id,
            },
          },
        });
      } catch (e) {
        console.log(e);
      }
      history.replace(`/${localIdentifier()}/menu/${link}`);
    }
  };

  // Handle the ads
  const [adStat] = useMutation<
    CreateAdLogMutation,
    CreateAdLogMutationVariables
  >(CREAT_AD_LOG);

  const [categoryStat] = useMutation<
    CreateCategoryNavigationMutation,
    CreateCategoryNavigationMutationVariables
  >(CREATECATEGORYNAVIGATION);

  const [productStat] = useMutation<
    CreateProductNavigationMutation,
    CreateProductNavigationMutationVariables
  >(CREATEPRODUCTNAVIGATION);

  const getAccessMenuAdsRes = useQuery<
    GetAccessMenuAdsQuery,
    GetAccessMenuAdsQueryVariables
  >(GETLISTMENUADS, {
    variables: {
      input: {
        menu: data?.getAccessMenuDetail.accessMenu?.id || "",
        position: "HOME",
      },
    },
  });

  const ads = getAccessMenuAdsRes.data?.getAccessMenuAds.list;
  // eslint-disable-next-line consistent-return
  const getCategoryPicture = (category: AccessMenuCategoryPayload) => {
    if (category.copiedPictures && category.copiedPictures.length > 0) {
      return category.copiedPictures.filter(
        (picture: FilePayload) => picture.quality === "LOW"
      )?.[0]?.fileUrl;
    }
    if (category.picture?.fileUrl) {
      return category.picture?.fileUrl;
    }
  };

  const hadleClickAd = async (adDetails: AccessMenuAdPayload) => {
    try {
      await adStat({
        variables: {
          input: {
            ad: adDetails.id || "",
            kind: "AD_CLICK",
          },
        },
      });
    } catch (error) {
      console.log(error);
    }

    setAd(adDetails);
    setShowPromotion(true);
  };

  // eslint-disable-next-line consistent-return
  const getAdPoster = () => {
    if (ad.pictures?.poster?.fileName) {
      return (
        <img
          id="poster"
          src={`${MENU_ADS_URL}${ad.pictures?.poster?.fileName}`}
          alt="ad"
        />
      );
    }
    if (ad.pictures?.cover?.fileName) {
      return (
        <img
          id="cover"
          src={`${MENU_ADS_URL}${ad.pictures?.cover?.fileName}`}
          alt="ad"
        />
      );
    }
  };

  const handleClose = () => {
    setShowPromotion(false);
  };

  // !END Handle the ads
  function getLanguageIndex<T extends { languageCode?: string | null }>(
    list: Pick<T, "languageCode">[]
  ): number {
    return list?.findIndex((item) => item?.languageCode === localLanguage) || 0;
  }

  const handleShowAdProduct = async (product: MenuAdProductPayload) => {
    setShowPromotion(false);
    try {
      await productStat({
        variables: {
          input: {
            kind: "PRODUCT_ENTRY",
            device: localStorage.getItem("deviceId"),
            product: product.id || "",
          },
        },
      });
    } catch (error) {
      console.log(error);
    }

    if (
      product.picture?.fileUrl !== null ||
      product.longDescriptions?.[0].value !== ""
    ) {
      lock();
    }
    const productCategoryIdentifier = menuDetails?.categories?.filter(
      (categories) => categories.id === product.parentCategory
    )?.[0]?.identifier;
    // simple push no need replace
    if (productCategoryIdentifier) {
      history.push({
        pathname: `/${menuId}/menu/${productCategoryIdentifier}/${product?.identifier}`,
      });
    }
  };

  return (
    <>
      <Wrapper showOnMobile={showOnMobile}>
        <div className="categories-list">
          {position === "HOME" && (
            <MenuCarousel
              className="carousel"
              id={data?.getAccessMenuDetail.accessMenu?.id || ""}
              position={position}
              clickAd={hadleClickAd}
            />
          )}
          {menuDetails &&
            menuDetails?.categories?.map(
              (category: AccessMenuCategoryPayload, index: number) => {
                return (
                  <Subtitle
                    decoration={
                      menuTheme?.titleBackground?.[
                        menuTheme.titleBackground.findIndex(
                          (decoration) => decoration.kind === "CATEGORYTITLE"
                        )
                      ]?.value || "#"
                    }
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    img={getCategoryPicture(category)}
                    title={
                      category?.names?.[
                        getLanguageIndex<NamePayload>(category.names)
                      ]?.value || ""
                    }
                    onNavigate={() =>
                      handleNavigate(category.identifier, category.id)
                    }
                    category={category.picture?.fileUrl !== null || false}
                  />
                );
              }
            )}
        </div>
        {ads &&
          ads.length !== 0 &&
          ads.map((a) => {
            if (a.pictures?.cover?.kind === "MENU_AD_VIDEO")
              return !a.pictures?.poster?.fileName ? (
                <button
                  className="button__access"
                  type="button"
                  onClick={() => {
                    hadleClickAd(a);
                    history.push("/main/staywithus", {
                      url: a.pictures?.cover?.fileName,
                      menuId: data?.getAccessMenuDetail.accessMenu?.id,
                    });
                  }}
                >
                  {translate(localLanguage, 54)}
                </button>
              ) : (
                <button
                  style={{
                    margin: "1% auto",
                    border: "none",
                    backgroundColor: "rgba(0,0,0,0)",
                  }}
                  type="button"
                  onClick={() => {
                    hadleClickAd(a);
                    history.push("/main/staywithus", {
                      url: a.pictures?.cover?.fileName,
                      menuId: data?.getAccessMenuDetail.accessMenu?.id,
                    });
                  }}
                >
                  <div
                    style={{
                      width: "40vw",
                      height: "100px",
                      backgroundColor: "white",
                      backgroundImage: `url("${MENU_ADS_URL}/${a.pictures?.poster?.fileName}")`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </button>
              );
            return <></>;
          })}
      </Wrapper>
      {showPromotion && (
        <ModalPromotion show={showPromotion} onClose={handleClose}>
          {getAdPoster()}
          {ad.externalUrl !== null && (
            <a
              className="more"
              href={ad.externalUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={async () => {
                try {
                  await adStat({
                    variables: {
                      input: {
                        ad: ad.id || "",
                        kind: "URL_CLICK",
                      },
                    },
                  });
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              {translate(localLanguage, 2)}
            </a>
          )}
          <div className="ads-product-list">
            {ad.products &&
              ad.products.length > 0 &&
              ad.products.map((product, index) => {
                return (
                  <AdProduct
                    key={product.id || ""}
                    product={product}
                    rank={index}
                    onProductClick={() => handleShowAdProduct(product)}
                  />
                );
              })}
          </div>
        </ModalPromotion>
      )}
    </>
  );
};
type WrapperProps = {
  showOnMobile?: boolean;
};
const Wrapper = styled.div<WrapperProps>`
  display: ${({ showOnMobile }) => (showOnMobile ? "flex" : "none")};
  flex-direction: column;
  grid-area: categorieList;
  .categories-list {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  @media only screen and (min-width: 930px) {
    display: flex;
    flex-direction: column;
    .categories-list {
      max-height: calc(100vh - 100px);
    }
  }
`;
export default CategoriesList;
