import React, { FC, useState } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import _ from "lodash";

import { MenuAdProductPayload } from "../api/types";
import { URL } from "../api/config";
import client, {
  GetLocalStateQuery,
  GET_LOCAL_STATE,
} from "../Graphql/apolloClient";

type Product = {
  product: MenuAdProductPayload;
  rank: number;
  onProductClick?: () => void;
};

type WrapperType = {
  rank: number;
};

const AdProduct: FC<Product> = ({ product, rank, onProductClick }) => {
  const [localState, setlocalState] = useState<GetLocalStateQuery | undefined>(
    undefined
  );
  client
    .watchQuery<GetLocalStateQuery>({
      query: GET_LOCAL_STATE,
      fetchPolicy: "cache-only",
    })
    .subscribe({
      next: ({ data }) => {
        setlocalState(data);
      },
      error: (e) => console.error(e),
    });
  const localLanguage = localState?.language || "EN";

  const language = _.findIndex(product.names, {
    languageCode: localLanguage,
  });

  return (
    <Wrapper rank={rank} onClick={() => onProductClick && onProductClick()}>
      {product.picture && product.picture.fileUrl && (
        <img
          className="ad-product-thumbnail"
          src={URL! + product.picture?.fileUrl || ""}
          alt="product"
        />
      )}
      <h4
        className={`h4__title ${
          product.shortDescriptions &&
          product.shortDescriptions.length > language &&
          product.shortDescriptions?.[language].value === "" &&
          "h4__title--center"
        }`}
      >
        {product.names?.[language].value}
      </h4>
      {product.discount && product.discount?.percentage && (
        <label htmlFor="discount" className="label__discount">
          {product.discount?.percentage}%
        </label>
      )}
      <div className="description">
        {product.shortDescriptions &&
          product.shortDescriptions.length > language &&
          product.shortDescriptions?.[language].value}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div<WrapperType>`
  height: 65px;
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: ${(props) =>
    props.theme.direction === "RTL" ? "40px 1fr 80px" : "80px 1fr 40px"};
  grid-template-rows: auto 35px auto;
  margin: 0 15px 15px 15px;
  padding-top: 15px;
  border-top: ${(props) => (props.rank !== 0 ? "1px solid #f6f6f9" : "unset")};
  overflow: hidden;
  img.ad-product-thumbnail {
    grid-row: span 2;
    margin: auto;
    height: 100%;
    object-fit: contain;
    ${(props) => props.theme.direction === "RTL" && "order:3"}
  }
  .h4__title {
    margin: auto 0 0 0;
    font-size: 16px;
    color: #444444;
    text-align: ${(props) =>
      props.theme.direction === "RTL" ? "right" : "left"};
    ${(props) => props.theme.direction === "RTL" && "order:2"}
  }
  .h4__title--center {
    grid-row: span 2;
    margin: auto 0 !important;
  }
  div.description {
    grid-column: span 2;
    grid-row: span 2;
    font-size: 13px;
    line-height: 13px;
    color: #6a6a6a;
    text-align: ${(props) =>
      props.theme.direction === "RTL" ? "right" : "left"};
    ${(props) => props.theme.direction === "RTL" && "order:4"}
  }
  .label__discount {
    margin: 0 0 auto auto;
    color: #fb2020;
    font-family: "Marcelle Script";
    font-size: 20px;
    ${(props) => props.theme.direction === "RTL" && "order:1"}
  }
`;

export default AdProduct;
