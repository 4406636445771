import React, { FC, useState } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";

import { AccessMenuCategoryProductPayload } from "../api/types";
import { translate } from "../utils/dictionary";
import client, {
  GetLocalStateQuery,
  GET_LOCAL_STATE,
} from "../Graphql/apolloClient";

type ModalType = {
  show: boolean;
  onClose: () => void;
  product?: AccessMenuCategoryProductPayload;
};

type ContainerType = {
  show: boolean;
  lang: string;
};

const ModalSettings: FC<ModalType> = ({ show, onClose, children }) => {
  const [localState, setlocalState] = useState<GetLocalStateQuery | undefined>(
    undefined
  );
  client
    .watchQuery<GetLocalStateQuery>({
      query: GET_LOCAL_STATE,
      fetchPolicy: "cache-only",
    })
    .subscribe({
      next: ({ data }) => {
        setlocalState(data);
      },
      error: (e) => console.error(e),
    });
  const localLanguage = localState?.language || "EN";

  const close = () => {
    onClose();
  };

  return (
    <Wrapper show={show} lang={localLanguage}>
      <div
        onClick={close}
        onKeyDown={close}
        role="button"
        tabIndex={0}
        aria-label="button"
        className="modal__overlay"
      />
      <div className="modal__view">
        <div className="modal__content">
          <h4>{translate(localLanguage, 1)}</h4>
          <hr className="__seperator" />
          <div className="language-card">{children}</div>
        </div>
        <div
          onClick={close}
          onKeyDown={close}
          role="button"
          tabIndex={0}
          aria-label="button"
          className="button__cancel"
        >
          <span>{translate(localLanguage, 3)}</span>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div<ContainerType>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: ${(props) => (props.show === true ? "99999" : "-1")};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  opacity: ${(props) => (props.show === true ? "1" : "0")};
  pointer-events: ${(props) => (props.show === true ? "unset" : "none")};
  .modal__overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${(props) => (props.show === true ? "98" : "-1")};
    display: ${(props) => (props.show === true ? "block" : "none")};
    background: ${(props) =>
      props.show === true ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0)"};
    transition: all 0.3s cubic-bezier(0.18, 1.04, 0.7, 1);
  }
  .modal__view {
    width: 90%;
    max-width: 460px;
    position: fixed;
    bottom: ${(props) => (props.show === true ? "20px" : "-100vh")};
    transition: all 0.3s cubic-bezier(0.18, 1.04, 0.7, 1);
    z-index: 100;
    display: grid;
    grid-template-rows: auto auto;
    grid-row-gap: 20px;
  }
  .modal__content {
    max-width: 460px;
    background: #ffffff;
    border-radius: 15px;
  }
  h4 {
    margin: 20px 20px 15px;
    font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    color: #444444;
    text-align: ${(props) =>
      props.theme.direction === "RTL" ? "right" : "left"};
    ::first-letter {
      text-transform: ${(props) =>
        props.theme.direction === "RTL" ? "unset" : "uppercase"};
    }
  }

  .content-header {
    display: grid;
    grid-template-columns: auto 80px;
  }
  .language-list {
    width: 100%;
    display: grid;
    grid-template-columns: ${(props) =>
      props.theme.direction === "RTL" ? "auto 45px" : "45px auto"};
    border-radius: 15px;
    p {
      padding: ${(props) =>
        props.theme.direction === "RTL" ? "0 15px 0 0" : "0  0 0 15px"};
      font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
      text-align: ${(props) =>
        props.theme.direction === "RTL" ? "right" : "left"};
      order: ${(props) => (props.theme.direction === "RTL" ? "1" : "2")};
    }
    img {
      width: 25px;
      margin: ${(props) =>
        props.theme.direction === "RTL"
          ? "auto auto auto 0"
          : "auto 0 auto auto"};
      order: ${(props) => (props.theme.direction === "RTL" ? "2" : "1")};
    }
  }
  .button__cancel {
    width: 100%;
    max-width: 460px;
    height: 55px;
    z-index: 99;
    display: flex;
    justify-content: center;
    padding: 0;
    align-items: center;
    background: white;
    color: rgb(255, 59, 48);
    border: 0;
    border-radius: 15px;
    font-size: 20px;
    font-weight: 500;
    font-family: "Cairo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    vertical-align: middle;
    outline: none;
  }
  hr.__seperator {
    height: 1px;
    margin: 8px 0 0 0;
    border: 0;
    border-bottom: 1px solid #f5f5f5;
  }
  .language-card {
    max-height: 400px;
    padding: 15px;
    overflow-y: auto;
  }

  @media only screen and (min-width: 930px) {
    .modal__overlay {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    .modal__view {
      bottom: 2vh;
    }
  }
  @media only screen and (min-height: 1024px) {
    .modal__view {
      bottom: calc(50vh - 38vh);
    }
  }
`;

export default ModalSettings;
