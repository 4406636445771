import React, { FC, useState } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";

import _ from "lodash";
import { URL } from "../api/config";
import { AccessMenuProductPayload, ProductPayload } from "../api/types";
import { getPrice } from "../utils/util";
import { translate } from "../utils/dictionary";

import client, {
  GetLocalStateQuery,
  GET_LOCAL_STATE,
} from "../Graphql/apolloClient";

type ItemType = {
  data: AccessMenuProductPayload | ProductPayload;
  display: "GRID" | "LIST" | "CENTER";
  image?: string;
  order?: number;
  show: () => void;
};

const Item: FC<ItemType> = ({ display, show, data, image, order }) => {
  const handleProduct = () => {
    show();
  };

  const [localState, setlocalState] = useState<GetLocalStateQuery | undefined>(
    undefined
  );
  client
    .watchQuery<GetLocalStateQuery>({
      query: GET_LOCAL_STATE,
      fetchPolicy: "cache-only",
    })
    .subscribe({
      next: ({ data }) => {
        setlocalState(data);
      },
      error: (e) => console.error(e),
    });
  const localLanguage = localState?.language || "EN";
  const localPrecision = localState?.precision || "1";

  const language: number = _.findIndex(data.names, {
    languageCode: localLanguage,
  });
  return (
    <Wrapper
      grid
      onClick={handleProduct}
      id="item"
      style={{ order }}
      borderImage={"borderImage" in data ? data.borderImage : null}
    >
      <div className={display}>
        {display === "GRID" && (
          <div className="image-thumbnail">
            <img src={URL! + image} alt="product" className="product-img" />
          </div>
        )}

        <div className="product-description">
          <span className="title">
            {data &&
              data.names &&
              data.names.length > 0 &&
              (data.names?.[language]?.value || "")}
            {!data.isAvailable && (
              <span
                style={{ fontSize: 12, fontWeight: "bold" }}
              >{`  (${translate(localLanguage, 50)})`}</span>
            )}
          </span>

          <span className="description">
            {data &&
              data.shortDescriptions &&
              data.shortDescriptions.length > 0 &&
              (data.shortDescriptions?.[language]?.value || "")}
          </span>
        </div>
        <div className="price-container">
          {!!data.price?.value && (
            <label
              id="discount"
              htmlFor="price"
              className={
                data.discount?.price?.value ? "price discount" : "price"
              }
            >
              <span
                className={
                  data.discount?.price?.value ? "discounted-price" : ""
                }
              >
                {`${getPrice(data.price?.value.toString(), localPrecision)} `}

                {data.price?.currency?.code || ""}
              </span>
            </label>
          )}
          {data.discount?.price?.value && (
            <label id="price" className="price" htmlFor="price">
              {`${getPrice(
                data.discount?.price?.value.toString(),
                localPrecision
              )} `}
              <span>{data.discount?.price?.currency?.code || ""}</span>
            </label>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

type WrapperProps = {
  grid?: boolean;
  list?: boolean;
  borderImage?: string | null;
};

const Wrapper = styled.div<WrapperProps>`
  height: fit-content;
  border: ${({ borderImage }) => (borderImage ? `25px solid gray` : "none")};
  border-image: ${({ borderImage }) =>
    borderImage ? `url(${URL}${borderImage}) 20% 20% stretch` : "none"};
  background: ${({ theme }) => theme.colorBackgroundCard || "transparent"};
  .LIST {
    display: flex;
    flex-direction: ${(props) =>
      props.theme.direction === "RTL" ? "row-reverse" : "row"};
    margin: 25px 0;
    .product-img {
      width: 45px;
      height: 45px;
      object-fit: cover;
    }
    .product-description {
      width: 70%;
      display: grid;
      grid-template-rows: auto auto;
      grid-gap: 5px;
      text-align: ${(props) =>
        props.theme.direction === "RTL" ? "right" : "left"};
      .title {
        font-family: ${(props) =>
          props.theme.direction === "RTL"
            ? "Cairo"
            : props.theme.police.primary};
        color: ${(props) => props.theme.paletteColors.primary};
        font-size: 20px;

        ::first-letter {
          ${(props) =>
            props.theme.direction === "RTL" ? "unset" : "uppercase"};
        }
      }

      .description {
        font-family: ${(props) =>
          props.theme.direction === "RTL"
            ? "Cairo"
            : props.theme.police.secondary};
        color: ${(props) => props.theme.paletteColors.primary};
        font-size: 12px;
        ::first-letter {
          text-transform: ${(props) =>
            props.theme.direction === "RTL" ? "unset" : "uppercase"};
        }
      }
    }
    .price-container {
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: ${(props) =>
        props.theme.direction === "RTL" ? "flex-start" : "flex-end"};
      .price {
        font-family: ${(props) =>
          props.theme.direction === "RTL"
            ? "Cairo"
            : props.theme.police.primary};
        color: ${(props) => props.theme.paletteColors.secondary};
        font-size: 18px;
        margin-top: auto;
        text-align: ${(props) =>
          props.theme.direction === "RTL" ? "left" : "right"};
      }
      .discount {
        font-size: 14px;
        color: ${(props) => props.theme.paletteColors.primary};
        span {
          text-decoration: line-through;
        }
      }
    }
  }
  .GRID {
    width: 100%;
    display: grid;
    grid-template-rows: auto;
    grid-gap: 4px;
    .product-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .product-description {
      display: grid;
      grid-template-rows: auto auto;
      grid-gap: 5px;
      .title {
        font-family: ${(props) =>
          props.theme.direction === "RTL"
            ? "Cairo"
            : props.theme.police.primary};
        color: ${(props) => props.theme.paletteColors.primary};
        font-size: 18px;
        margin: 8px auto auto auto;
        text-align: center;
        line-height: ${(props) =>
          props.theme.direction === "RTL" ? "22px" : "unset"};
      }
      .description {
        font-family: ${(props) =>
          props.theme.direction === "RTL"
            ? "Cairo"
            : props.theme.police.secondary};
        color: ${(props) => props.theme.paletteColors.primary};
        font-size: 12px;
        margin: auto;
        text-align: center;
        ::first-letter {
          ${(props) =>
            props.theme.direction === "RTL" ? "unset" : "uppercase"};
        }
      }
    }
    .price-container {
      display: flex;
      flex-direction: column;
    }
    .price {
      font-family: ${(props) =>
        props.theme.direction === "RTL" ? "Cairo" : props.theme.police.primary};
      color: ${(props) => props.theme.paletteColors.secondary};
      font-size: 18px;
      margin: 6px auto;
      text-align: center;
      line-height: ${(props) =>
        props.theme.direction === "RTL" ? "20px" : "unset"};
    }
    .discount {
      font-size: 14px;
      margin: auto;
      color: ${(props) => props.theme.paletteColors.primary};
      span {
        text-decoration: line-through;
      }
    }
  }

  .CENTER {
    display: flex;
    flex-direction: column;
    margin: 25px 0;
    .product-img {
      display: none;
    }
    .product-description {
      display: grid;
      grid-template-rows: auto auto;
      grid-gap: 5px;
      text-align: center;
      .title {
        font-family: ${(props) =>
          props.theme.direction === "RTL"
            ? "Cairo"
            : props.theme.police.primary};
        color: ${(props) => props.theme.paletteColors.primary};
        font-size: 20px;
        ::first-letter {
          ${(props) =>
            props.theme.direction === "RTL" ? "unset" : "uppercase"};
        }
      }
      .description {
        font-family: ${(props) =>
          props.theme.direction === "RTL"
            ? "Cairo"
            : props.theme.police.secondary};
        color: ${(props) => props.theme.paletteColors.primary};
        font-size: 12px;
        ::first-letter {
          text-transform: ${(props) =>
            props.theme.direction === "RTL" ? "unset" : "uppercase"};
        }
      }
    }
    .price-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .price {
        font-family: ${(props) =>
          props.theme.direction === "RTL"
            ? "Cairo"
            : props.theme.police.primary};
        color: ${(props) => props.theme.paletteColors.secondary};
        font-size: 18px;
        margin-top: auto;
        text-align: center;
      }
      .discount {
        font-size: 14px;
        color: ${(props) => props.theme.paletteColors.primary};
        span {
          text-decoration: line-through;
        }
      }
    }
  }
  .discounted-price {
    text-decoration: line-through;
    margin-top: 10px;
    display: block;
  }
  .image-thumbnail {
    display: flex;
    width: 100%;
    height: 140px;
    margin: auto;
    overflow: hidden;
    border-radius: 5px;
  }
  @media only screen and (min-width: 930px) {
    .image-thumbnail {
      height: 170px;
    }
  }
`;

export default Item;
