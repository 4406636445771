/* eslint-disable no-console */
/* eslint-disable import/no-cycle */
import React, { FC, useEffect, useMemo, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { X, Copy } from "react-feather";

import { getCookie } from "../../utils/util";
import { QuantitySelector } from "../../Commons";
import bag from "../../Images/Bag_blue.svg";
import { translate } from "../../utils/dictionary";
import TabMain from "./TabMain/TabMain";
import TabExtras from "./TabExtras/TabExtras";
import { Hr, Snack, Loader, Wrapper } from "./ModalProduct--styles";
import { copyLink, getProductUnit } from "./ModalProduct--utils";
import {
  GetProductDetailsQuery,
  GetProductDetailsQueryVariables,
  AddToMenuCartMutation,
  AddToMenuCartMutationVariables,
  GetMenuCartDetailsQuery,
  GetMenuCartDetailsQueryVariables,
  RemoveFromMenuCartMutation,
  RemoveFromMenuCartMutationVariables,
  UpdateMenuCartQuatityMutation,
  UpdateMenuCartQuatityMutationVariables,
  MenuCartSupplementInput,
} from "../../api/types";
import {
  GET_PRODUCT_DETAILS,
  GET_MENU_CART_DETAILS,
} from "../../api/query.api";
import {
  ADD_TO_MENU_CART,
  REMOVE_FROM_MENU_CART,
  UPDATE_MENU_CART_QUATITY,
} from "../../api/mutation.api";

import client, {
  localDomain,
  localIdentifier,
  GetLocalStateQuery,
  GET_LOCAL_STATE,
} from "../../Graphql/apolloClient";

type ModalProductProps = {
  show: boolean;
  onClose: () => void;
  menu: string;
};

type ParamsType = {
  idProduct: string;
};

type SnackActionType = {
  show: boolean;
  type:
    | "snack_copy"
    | "snack_add"
    | "snack_remove"
    | "snack_update"
    | undefined;
};

type Options = {
  [key: string]: MenuCartSupplementInput[];
};

const ModalProduct: FC<ModalProductProps> = ({ show, onClose, menu }) => {
  const { idProduct } = useParams<ParamsType>();
  const device = getCookie();
  const category = window.location.pathname?.split("/")[3];

  const [quantity, setQuantity] = React.useState<string>("1");
  const [notes, setNote] = React.useState("");
  const [addAnimate, setAddAnimate] = React.useState<SnackActionType>({
    show: false,
    type: undefined,
  });
  const [tab, setTab] = React.useState(1);
  const [options, setOptions] = React.useState<Options>({});
  // console.log("#options", options);
  const [optionsErrors, setOptionsErrors] = React.useState<{
    [key: string]: string;
  }>({});
  const [totalSupplementsPrice, setTotalSupplementsPrice] = React.useState({
    total: 0,
    currency: "QR",
  });

  const [localState, setlocalState] = useState<GetLocalStateQuery | undefined>(
    undefined
  );
  client
    .watchQuery<GetLocalStateQuery>({
      query: GET_LOCAL_STATE,
      fetchPolicy: "cache-only",
    })
    .subscribe({
      next: ({ data }) => {
        setlocalState(data);
      },
      error: (e) => console.error(e),
    });
  const localLanguage = localState?.language || "EN";
  const localShop = localState?.shop;

  const close = () => {
    onClose();
  };

  const { data: selectedProduct, loading } = useQuery<
    GetProductDetailsQuery,
    GetProductDetailsQueryVariables
  >(GET_PRODUCT_DETAILS, {
    variables: {
      input: {
        domain: localDomain(),
        menu: localIdentifier(),
        category,
        identifier: idProduct || "",
      },
    },
  });

  const { data: cart } = useQuery<
    GetMenuCartDetailsQuery,
    GetMenuCartDetailsQueryVariables
  >(GET_MENU_CART_DETAILS, {
    variables: { input: { device, menu } },
    fetchPolicy: "network-only",
  });
  // console.log(cart?.getMenuCartDetails);

  // LOCAL VARIABLES
  const product = selectedProduct?.getProductDetails.product;
  const unit = selectedProduct?.getProductDetails.product.unit?.default || 1;
  const browser: any = window.navigator;
  const shop = localShop === "ON";

  //  MUTATIONS
  const supplements = useMemo(() => Object.values(options).flat(), [options]);
  const [AddToCart] = useMutation<
    AddToMenuCartMutation,
    AddToMenuCartMutationVariables
  >(ADD_TO_MENU_CART, {
    variables: {
      input: {
        device,
        product: product?.id || "",
        quantity: parseFloat(quantity) / unit,
        notes,
        supplements,
      },
    },
    awaitRefetchQueries: true,
    refetchQueries: () => [
      {
        query: GET_MENU_CART_DETAILS,
        variables: { input: { device, menu } },
      },
    ],
    onCompleted: () => {
      setAddAnimate({ type: "snack_add", show: true });
      hideSnack();
    },
    fetchPolicy: "no-cache",
  });

  const [remove] = useMutation<
    RemoveFromMenuCartMutation,
    RemoveFromMenuCartMutationVariables
  >(REMOVE_FROM_MENU_CART, {
    variables: { input: { device, product: product?.id || "" } },
    awaitRefetchQueries: true,
    refetchQueries: () => [
      {
        query: GET_MENU_CART_DETAILS,
        variables: { input: { device, menu } },
      },
    ],
    fetchPolicy: "no-cache",
  });

  const [updateQuantity] = useMutation<
    UpdateMenuCartQuatityMutation,
    UpdateMenuCartQuatityMutationVariables
  >(UPDATE_MENU_CART_QUATITY, {
    awaitRefetchQueries: true,
    refetchQueries: () => [
      {
        query: GET_MENU_CART_DETAILS,
        variables: { input: { device, menu } },
      },
    ],
    fetchPolicy: "no-cache",
  });

  const productId = selectedProduct?.getProductDetails.product.id;
  const producIndextInCart = cart?.getMenuCartDetails?.products?.findIndex(
    (productItem) => productItem.product?.id === productId
  );
  // console.log(cart?.getMenuCartDetails, producIndextInCart);

  const hasExtras = !!product?.choices?.length;

  // FUNCTIONS
  useEffect(() => {
    let priceTotal: number | undefined = 0;

    let categoriesIds = Object.keys(options);
    let categories = product?.choices?.filter(
      (choice) => categoriesIds.includes(String(choice.id)) && choice
    );
    let optionsIds = Object.values(options)
      .flat()
      .map((el) => el.id);
    let selectedOptions = product?.choices
      ?.map((el) => {
        return {
          category: el,
          options: el?.supplements?.filter((item) => {
            return optionsIds.includes(item.id);
          }),
        };
      })
      .filter((el) => el?.options && el?.options.length > 0);
    let sortedOptions = selectedOptions
      ? selectedOptions.map(
          (el) =>
            el.options &&
            el.options.sort((a, b) =>
              (a.price?.value || 0) > (b.price?.value || 0) ? 1 : -1
            )
        )
      : [];
    let PayedOptions = sortedOptions
      .map((el, i) =>
        el?.filter(
          (option, index) =>
            //@ts-ignore
            selectedOptions[i]?.category?.freeSupplements < index + 1
        )
      )
      .flat();
    console.log("#selectedOptions", selectedOptions);
    console.log("#sortedOptions", sortedOptions);
    console.log("#PayedOptions", PayedOptions);

    priceTotal = PayedOptions?.reduce(
      (previousValue, currentValue) =>
        previousValue + (currentValue?.price?.value || 0),
      0
    );
    setTotalSupplementsPrice({
      total: priceTotal || 0,
      currency:
        (product?.choices &&
          product?.choices?.length > 0 &&
          product?.choices[0]?.supplements &&
          product?.choices[0]?.supplements[0]?.price?.currency?.code) ||
        "QR",
    });
  }, [options]);

  useEffect(() => {
    if (
      !hasExtras &&
      producIndextInCart !== undefined &&
      producIndextInCart !== -1
    ) {
      let newQuantity =
        cart?.getMenuCartDetails?.products?.[producIndextInCart]?.quantity;
      // show the quantity * unit
      if (newQuantity) newQuantity *= unit;
      setQuantity(newQuantity ? newQuantity.toString() : "1");
    } else {
      setQuantity(product?.unit?.default?.toString() || "");
    }
  }, [producIndextInCart, cart, product, unit, hasExtras]);

  const handleQuantity = (
    quantityValue: string,
    productExistValue: number | undefined,
    id: string | null | undefined
  ) => {
    // test in case only Integer is allowed
    if (
      quantityValue &&
      (product?.unit?.kind === "PIECE" || product?.unit?.kind === "GRAM")
    ) {
      if (!/^\d+$/.test(quantityValue)) {
        return;
      }
      // test if is a decimal
    } else if (!/^\d*\.?\d*$/.test(quantityValue)) {
      return;
    }
    if (
      productExistValue !== undefined &&
      productExistValue !== -1 &&
      parseFloat(quantityValue) > 0 &&
      unit &&
      id
    ) {
      updateQuantity({
        variables: {
          input: {
            device,
            product: id,
            quantity: parseFloat(quantityValue) / unit,
          },
        },
      }).then(() => {
        setAddAnimate({ type: "snack_update", show: true });
        hideSnack();
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onUpdateQuantity = React.useCallback(
    _.debounce(handleQuantity, 1500),
    []
  );

  const handleOnChange = (quantityValue: string) => {
    setQuantity(quantityValue);
    if (!hasExtras)
      onUpdateQuantity(quantityValue, producIndextInCart, productId);
  };

  const handleAddQuantity = () => {
    const curretQuantity: string = (parseFloat(quantity) + unit).toString();
    setQuantity(curretQuantity);
    if (!hasExtras)
      onUpdateQuantity(curretQuantity, producIndextInCart, productId);
  };

  const handleReduceQuantity = () => {
    const curretQuantity = parseFloat(quantity);
    if (curretQuantity - unit > 0) {
      setQuantity((curretQuantity - unit).toString());
      if (!hasExtras)
        onUpdateQuantity(
          (curretQuantity - unit).toString(),
          producIndextInCart,
          productId
        );
    }
  };

  const hideSnack = () => {
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    setTimeout(() => setAddAnimate({ ...addAnimate, show: false }), 3500);
  };

  const handleAddToCart = () => {
    if (product?.choices && product.choices.length > 0) {
      const validationErrors: { [key: string]: string } = {};
      product.choices.forEach((extra) => {
        if (extra.minItems || extra.maxItems) {
          if (options[extra.id!]) {
            if (extra.minItems && options[extra.id!].length < extra.minItems) {
              validationErrors[extra.id!] = `${translate(localLanguage, 43)} ${
                extra.minItems
              } ${translate(localLanguage, 42)}`;
            } else if (
              extra.maxItems &&
              options[extra.id!].length > extra.maxItems
            ) {
              validationErrors[extra.id!] = `${translate(localLanguage, 41)} ${
                extra.maxItems
              } ${translate(localLanguage, 42)}`;
            }
          } else if (extra.minItems && extra.minItems > 0) {
            validationErrors[extra.id!] = `${translate(localLanguage, 43)} ${
              extra.minItems
            } ${translate(localLanguage, 42)}`;
          }
        }
      });
      if (Object.keys(validationErrors).length === 0) {
        AddToCart();
      } else {
        setOptionsErrors(validationErrors);
      }
    } else {
      AddToCart();
    }
  };

  const share = (name: string) => {
    if (browser && browser.share) {
      browser
        .share({
          title: name,
          url: window.location.href,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch((err: any) => {
          console.log(`Couldn't share because of`, err.message);
        });
    } else {
      setAddAnimate({ type: "snack_copy", show: true });
      hideSnack();
      copyLink();
    }
  };
  return (
    <Wrapper show={show} shop={shop}>
      <div
        role="button"
        onClick={close}
        className="modal__overlay"
        tabIndex={0}
        aria-label="button"
        onKeyDown={close}
      />
      {loading ? (
        <div className="modal__content">
          <Loader color="#00004E">
            <div id="container">
              <span className="sp1">
                <span className="sp2">
                  <span className="sp3" />
                </span>
              </span>
            </div>
          </Loader>
        </div>
      ) : (
        <div className="modal__content">
          <Snack
            id="snack_copy"
            className={`${
              addAnimate.type === "snack_copy" && addAnimate.show === true
                ? "animate"
                : ""
            }`}
          >
            <div className="snack-content">
              <Copy color="#ffffff" size="18" strokeWidth="2" />
              <span>{translate(localLanguage, 8)}</span>
              <textarea
                id="link"
                hidden
                disabled
                defaultValue={window.location.href}
              />
            </div>
          </Snack>
          <Snack
            id="snack_add"
            className={`${
              addAnimate.type === "snack_add" && addAnimate.show === true
                ? "animate"
                : ""
            }`}
          >
            <div className="snack-content">
              <span>{translate(localLanguage, 9)}</span>
            </div>
          </Snack>
          <Snack
            id="snack_remove"
            className={`${
              addAnimate.type === "snack_remove" && addAnimate.show === true
                ? "animate"
                : ""
            }`}
          >
            <div className="snack-content">
              <span>{translate(localLanguage, 10)}</span>
            </div>
          </Snack>
          <Snack
            id="snack_update"
            className={`${
              addAnimate.type === "snack_update" && addAnimate.show === true
                ? "animate"
                : ""
            }`}
          >
            <div className="snack-content">
              <span>{translate(localLanguage, 38)}</span>
            </div>
          </Snack>
          <button type="button" className="close" onClick={close}>
            <X color="#ffffff" />
          </button>
          {product && tab === 1 && <TabMain share={share} product={product} />}
          {product && tab === 2 && (
            <div>
              <TabExtras
                product={product}
                options={options as any}
                errors={optionsErrors}
                onChangeOptions={(newOptions) => {
                  setOptions(newOptions as any);
                  setOptionsErrors({});
                }}
              />
              <p className="quatity">
                {translate(localLanguage, 57)}: {totalSupplementsPrice.total}{" "}
                {totalSupplementsPrice.currency}
              </p>
            </div>
          )}
          {shop && (
            <div className="cart-manager">
              <Hr />
              <div>
                <div className="quatity">
                  <span>
                    {translate(localLanguage, 36)}
                    {getProductUnit(product?.unit?.kind || "")}
                  </span>
                  <QuantitySelector
                    quantity={quantity}
                    unit={product?.unit?.kind || ""}
                    onAdd={handleAddQuantity}
                    onReduce={handleReduceQuantity}
                    onChange={(value) => handleOnChange(value)}
                    disabled={!product?.isAvailable}
                  />
                </div>
                <input
                  className="note"
                  placeholder={translate(localLanguage, 26)}
                  name="note"
                  value={notes}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>
              <Hr />
              {producIndextInCart !== -1 && !hasExtras && (
                <button
                  type="button"
                  className="button__add-cart"
                  onClick={() => {
                    remove();
                    setAddAnimate({ type: "snack_remove", show: true });
                    hideSnack();
                  }}
                >
                  <img src={bag} alt="cart" className="img__icon" />
                  <span>{translate(localLanguage, 7)}</span>
                </button>
              )}
              {((producIndextInCart === -1 && !hasExtras) ||
                (hasExtras && tab === 2)) && (
                <button
                  type="button"
                  className={`button__add-cart
                       ${!parseFloat(quantity) && "button__add-cart--disable"}`}
                  disabled={!parseFloat(quantity) || !product?.isAvailable}
                  onClick={() => {
                    handleAddToCart();
                    setNote("");
                  }}
                >
                  <img src={bag} alt="cart" className="img__icon" />
                  <span>
                    {product?.isAvailable
                      ? translate(localLanguage, 6)
                      : translate(localLanguage, 50)}
                  </span>
                </button>
              )}
              {hasExtras && tab === 1 && (
                <button
                  type="button"
                  className={`button__add-cart
                     ${!parseFloat(quantity) && "button__add-cart--disable"}`}
                  disabled={!parseFloat(quantity) || !product?.isAvailable}
                  onClick={() => setTab(2)}
                >
                  <img src={bag} alt="cart" className="img__icon" />
                  <span>
                    {product?.isAvailable
                      ? translate(localLanguage, 40)
                      : translate(localLanguage, 50)}
                  </span>
                </button>
              )}
            </div>
          )}
        </div>
      )}
    </Wrapper>
  );
};

export default ModalProduct;
