//  libraries imports
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Trash2 } from "react-feather";
import { useMutation, useQuery } from "@apollo/client";
import _ from "lodash";

//  local imports
import { getCookie } from "../utils/util";
// eslint-disable-next-line import/no-cycle
import { QuantitySelector } from ".";
import { translate } from "../utils/dictionary";
import {
  CartProductPayload,
  RemoveFromMenuCartMutation,
  RemoveFromMenuCartMutationVariables,
  UpdateMenuCartQuatityMutation,
  UpdateMenuCartQuatityMutationVariables,
  GetAccessMenuDetailQueryVariables,
  GetAccessMenuDetailQuery,
} from "../api/types";
import {
  REMOVE_FROM_MENU_CART,
  UPDATE_MENU_CART_QUATITY,
} from "../api/mutation.api";
import { GET_MENU_CART_DETAILS, GETMENU } from "../api/query.api";
import { URL } from "../api/config";
import client, {
  localDomain,
  localIdentifier,
  GetLocalStateQuery,
  GET_LOCAL_STATE,
} from "../Graphql/apolloClient";

type CartProductType = {
  data: CartProductPayload;
  menu: string;
};

type WrapperType = {
  image: boolean;
};

const CartProduct: FC<CartProductType> = ({ data, menu }) => {
  const device = getCookie();

  //  STATES
  const [quantity, setQuantity] = React.useState<string>("1");

  const [localState, setlocalState] = useState<GetLocalStateQuery | undefined>(
    undefined
  );
  client
    .watchQuery<GetLocalStateQuery>({
      query: GET_LOCAL_STATE,
      fetchPolicy: "cache-only",
    })
    .subscribe({
      next: ({ data }) => {
        setlocalState(data);
      },
      error: (e) => console.error(e),
    });
  const localLanguage = localState?.language || "EN";

  const { data: menuDetails } = useQuery<
    GetAccessMenuDetailQuery,
    GetAccessMenuDetailQueryVariables
  >(GETMENU, {
    variables: {
      input: { domain: localDomain(), identifier: localIdentifier() },
    },
  });

  // MUTATIONS
  const [remove] = useMutation<
    RemoveFromMenuCartMutation,
    RemoveFromMenuCartMutationVariables
  >(REMOVE_FROM_MENU_CART, {
    variables: { input: { device, product: data.product?.id || "" } },
    awaitRefetchQueries: true,
    refetchQueries: () => [
      {
        query: GET_MENU_CART_DETAILS,
        variables: { input: { device, menu } },
      },
    ],
    fetchPolicy: "no-cache",
  });

  const [updateQuantity] = useMutation<
    UpdateMenuCartQuatityMutation,
    UpdateMenuCartQuatityMutationVariables
  >(UPDATE_MENU_CART_QUATITY, {
    awaitRefetchQueries: true,
    refetchQueries: () => [
      {
        query: GET_MENU_CART_DETAILS,
        variables: { input: { device, menu } },
      },
    ],
    fetchPolicy: "no-cache",
  });

  //  LOCAL VARIABLES
  const image = data.product?.picture?.fileUrl;
  const unit = data.product?.unit?.default || 1;
  const language: number = _.findIndex(
    menuDetails?.getAccessMenuDetail.accessMenu?.languages,
    {
      languageCode: localLanguage,
    }
  );

  //  FUNCTIONS
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    data.quantity && setQuantity((data.quantity * unit).toString());
  }, [data, unit]);

  const handleQuantity = (quantityValue: string) => {
    // test in case only Integer is allowed
    if (
      quantityValue &&
      (data.product?.unit?.kind === "PIECE" ||
        data.product?.unit?.kind === "GRAM")
    ) {
      if (!/^\d+$/.test(quantityValue)) {
        return;
      }
      // test if is a decimal
    } else if (!/^\d*\.?\d*$/.test(quantityValue)) {
      return;
    }
    setQuantity(quantityValue);
    if (parseFloat(quantityValue) > 0 && unit) {
      updateQuantity({
        variables: {
          input: {
            device,
            product: data?.product?.id || "",
            cartItem: data?.id,
            quantity: parseFloat(quantityValue) / unit,
          },
        },
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onUpdateQuantity = React.useCallback(
    _.debounce(handleQuantity, 1000),
    []
  );

  const handleOnChange = (quantityValue: string) => {
    setQuantity(quantityValue);
    onUpdateQuantity(quantityValue);
  };

  const handleAddQuantity = () => {
    const curretQuantity: string = (parseFloat(quantity) + unit).toString();
    setQuantity(curretQuantity);
    onUpdateQuantity(curretQuantity);
  };

  const handleReduceQuantity = () => {
    const curretQuantity = parseFloat(quantity);
    if (curretQuantity - unit > 0) {
      setQuantity((curretQuantity - unit).toString());
      onUpdateQuantity((curretQuantity - unit).toString());
    }
  };

  return (
    <Wrapper image={!(image === null || image === undefined)}>
      {data.product?.picture?.fileUrl && (
        <img className="product-thumbnail" src={URL! + image} alt="product" />
      )}
      <div className="container">
        <h5 className="h5__product-name">
          {data.product?.names?.[language]?.value}
        </h5>
        <div className="container__price">
          <span className="unit-price">{translate(localLanguage, 37)}</span>
          <span className="unit-price">
            &nbsp;&nbsp;
            {`${data.unitPrice?.value} ${data.unitPrice?.currency?.code}`}
            &nbsp;&nbsp;
          </span>
        </div>
        <div className="container__price">
          <span className="unit-price">{translate(localLanguage, 55)}</span>
          <span className="unit-price">
            &nbsp;&nbsp;
            {`${data.prepTime || translate(localLanguage, 56)}`}
            &nbsp;&nbsp;
          </span>
        </div>
      </div>
      <h5 className="h5__product-price">
        {`${Math.round((data.totalPrice?.value || 0) * 100) / 100} ${
          data.totalPrice?.currency?.code
        }`}
      </h5>
      <p className="p__product-description">
        {data.product?.shortDescriptions?.[language]?.value}
      </p>
      {data.supplements && data.supplements.length > 0 && (
        <p className="p__product-description">
          {data.supplements?.map(
            (extra) => `+${extra?.names?.[language]?.value} `
          )}
        </p>
      )}
      {data.notes && (
        <div className="container__price">
          <span className="unit-price">{translate(localLanguage, 26)}:</span>
          <span className="unit-price">
            &nbsp;&nbsp;
            {data.notes}
            &nbsp;&nbsp;
          </span>
        </div>
      )}
      <div className="actions">
        <QuantitySelector
          quantity={quantity}
          unit={data.product?.unit?.kind || ""}
          onAdd={handleAddQuantity}
          onReduce={handleReduceQuantity}
          onChange={(value) => handleOnChange(value)}
        />
        <button
          type="button"
          className="button__delete"
          onClick={() => remove()}
        >
          <Trash2 color="#fb2020" size="20" />
        </button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div<WrapperType>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.image ? "65px 1fr 65px" : "1fr 65px"};
  grid-template-rows: auto auto auto;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  margin: 0 15px;
  padding: 15px 0;
  border-bottom: 1px solid rgba(219, 216, 213, 0.3);
  img.product-thumbnail {
    width: 65px;
    height: 65px;
    grid-row: span 4;
    border-radius: 10px;
    object-fit: cover;
  }
  h5 {
    margin: 0;
    color: #444444;
    font-size: 18px;
    margin-top: auto;
  }
  .h5__product-name {
    margin-top: 5px;
    line-height: 24px;
  }
  .h5__product-price {
    margin: 0;
    line-height: 24px;
  }
  .p__product-description {
    grid-column: span 2;
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    font-weight: 100;
    color: #979797;
  }
  .unit-price {
    display: block;
    font-size: 14px;
    color: #9a9ea9;
  }
  .container__price {
    display: flex;
    align-items: center;
  }
  .actions {
    grid-column: span 2;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  .button__delete {
    height: 44px;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${(props) =>
      props.theme.direction === "RTL" ? "0 10px 0 0" : "0 0 0 10px"};
    border-radius: 22px;
    border: 0;
    background: #fff2f2;
  }
`;

export default CartProduct;
